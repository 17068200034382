import React, { useState } from "react";
import { Table, Skeleton, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store/store';
import { addOrRemoveUserGraphSoftSkills } from '../../../../redux/actions/pages/offers/offersProfile/GetOffersProfile';
import CheckboxC from "../../../checkbox/CheckboxProps";
import RatingStar from "../../searchCV/RatingStar";
import InfiniteScroll from "react-infinite-scroll-component";
import ModalSoftSkills from "../ModalSoftSkills";
interface Column {
  title: string;
  dataIndex: string;
  key: string;
  render?: (text: any, record: any) => JSX.Element | string;
}

type NotificationType = 'success' | 'info' | 'warning' | 'error';

interface OffersTableProps {
  offerId: number;
  fetchMoreData : () => void,
  hasMore : boolean
}

const OffersTable: React.FC<OffersTableProps> = ({ 
  offerId, 
  fetchMoreData,
  hasMore
}) => {

  const dispatch = useDispatch<AppDispatch>();
  const [showSoftSkills, setShowSoftSkills] = useState<boolean>(false)
  
  const [api, contextHolder] = notification.useNotification();

  const {
    postulaciones,
    rex_limit,
    rex_users_graph_sk,
  } = useSelector((state: RootState) => state.getOffersProfile);

  const navigate = useNavigate();

  const handleCandidateClick = (candidateId: number) => {
    if (offerId && candidateId) {
      navigate(`/offers/${offerId}/candidate/${candidateId}`);
    } else {
      console.error("ID del candidato o de la oferta no definidos");
    }
  };

  const openNotificationWithIcon = (type: NotificationType) => {
    api[type]({
      message: 'Alerta',
      description:
        'Solo puedes seleccionar a 10 usuarios como máximo',
    });
  };

  const columnsWithCheckbox: Column[] = [
    {
      title: "",
      dataIndex: "checkbox",
      key: "checkbox",
      render: (text, record) => (
        <CheckboxC 
          checked={record.checked}
          onChange={({target: {checked}})=> {
            if(rex_users_graph_sk.users.length < 10 || checked == false){
              dispatch(addOrRemoveUserGraphSoftSkills(record, checked,false))
            }else{
              openNotificationWithIcon('warning')
            }
          }}
        />
      ),
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <div className="font-normal text-caption text-green32">
              {record.nombre}
            </div>
            <div className="font-bold text-body-sm text-green32">
              {record.rol}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Valoraciones",
      dataIndex: "valoraciones",
      key: "valoraciones",
      render: (text, record) => {
        const roundedRating = Number(record?.valoraciones?.toFixed(1) ?? 0);
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <RatingStar value={roundedRating} />
            <span className="font-bold text-body-sm ml-[4px]">
              {roundedRating}
            </span>
            <span className="font-normal text-body-sm text-green32 ml-[4px]">
              ({record.numReviews})
            </span>
          </div>
        );
      },
    },
    {
      title: "Aptitudes",
      dataIndex: "aptitudes",
      key: "aptitudes",
      render: (aptitudes) => (
        <div className="font-medium text-caption text-green32">
          {aptitudes.map((aptitud: string, index: number) => (
            index < 1 && (
              <div key={index}>{aptitud}</div>
            )
          ))}
        </div>
      ),
    },
    {
      title: "Soft Skills",
      dataIndex: "softSkills",
      key: "softSkills",
      render: (_, record) => (
        <div
          onClick={(e) => {
            e.stopPropagation()
            setShowSoftSkills(true)
            if(rex_users_graph_sk.users.length < 10){
              dispatch(addOrRemoveUserGraphSoftSkills(record, true, true))
            }else{
              openNotificationWithIcon('warning')
            }
          }}
          className="cursor-pointer text-caption text-blue3"
        >
          Ver Gráficas
        </div>
      ),
    },
    {
      title: "Ubicación",
      dataIndex: "ubicacion",
      key: "ubicacion",
      render: (text) => (
        <div className="font-normal text-body-sm text-green32">{text}</div>
      ),
    },
    {
      title: "Estudios",
      dataIndex: "estudios",
      key: "estudios",
      render: (educacion) => (
        <div className="font-medium text-body-sm text-green32">
          {educacion.map((carrera: string, index: number) => (
            index < 1 && (
              <div key={index}>{carrera}</div>
            )
          ))}
        </div>
      ),
    },
    {
      title: "Experiencia",
      dataIndex: "experiencia",
      key: "experiencia",
      render: (text) => (
        <div className="font-medium text-body-sm text-green32">{text}</div>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <InfiniteScroll
        dataLength={postulaciones.length}
        next={fetchMoreData}
        hasMore={hasMore && postulaciones.length >= rex_limit}
        loader={
          hasMore && postulaciones.length >= rex_limit ? ( // Solo muestra el Skeleton si `hasMore` es `true` y hay más resultados para cargar
            <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
              <Skeleton avatar paragraph={{ rows: 3 }} active />
            </div>
          ) : null
        }
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Ya no hay más datos</b>
          </p>
        }
        scrollThreshold={0.95}
      >
        <React.Fragment >
          <Table
            columns={columnsWithCheckbox}
            dataSource={postulaciones}
            pagination={false}
            rowKey={(record) => record.id}
            onRow={(record) => ({
              onClick: () => handleCandidateClick(Number(record.id)),
            })}
            className="offer-table cursor-pointer"
          />
        </React.Fragment>

      </InfiniteScroll>
      <style>{`
        .offer-table .ant-table-thead > tr > th {
          background-color: white;
          color: #5f5f5f;
          font-weight: normal;
          font-size: 12px;
          border-bottom: 1px solid #5F5F5F;
        }

        .offer-table .ant-table-thead > tr > th,
        .offer-table .ant-table-tbody > tr > td {
          padding: 12px;
          border-right: none;
        }
      `}</style>
      <ModalSoftSkills
        visible={showSoftSkills}
        onClose={() => setShowSoftSkills(false)}
        data={[]}
      />

    </>
  );
};

export default OffersTable;