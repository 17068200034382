import React, { useEffect } from 'react';
import { Layout } from 'antd';
import 'tailwindcss/tailwind.css';
import { useDispatch, useSelector } from 'react-redux';
import { GetOfferReducer } from '../../../../redux/actions/offers/GetOffers';
import { AppDispatch } from '../../../../redux/store/store';
import { useParams } from 'react-router-dom';

const { Sider } = Layout;

interface OfferDescriptionProps {
  jobTitle: string;
  jobLocation: string;
  salaryRange: string;
  jobDetails: string[];
  skills: string[];
  keywords: string[];
  inDrawer?: boolean;
  rex_request?: any;
}

interface Aptitude {
  id: number;
  aptitud: string;
  aprobado: boolean;
  createdAt: string;
  updatedAt: string;
}

interface AptitudeOffer {
  aptitudes: Aptitude;
}

const OfferDescription: React.FC<OfferDescriptionProps> = ({
  jobTitle,
  jobLocation,
  salaryRange,
  jobDetails,
  skills,
  keywords,
  inDrawer = false,
}) => {

  const dispatch = useDispatch<AppDispatch>();

  const { rex_data_offer } = useSelector(({ getOffers }: any) => getOffers);

  const { idOffer } = useParams();

  useEffect(() => {
    if (idOffer) {
      dispatch(GetOfferReducer(Number(idOffer)));
    }
  }, [idOffer]);

  const descriptionSalary = [
    {
      value: 'Mes',
      label: 'mensual',
    },
    {
      value: 'Hora',
      label: 'por hora',
    },
    {
      value: 'Año',
      label: 'anual',
    },
  ]

  const optionsWorkingDays = [
    { value: "todas", label: "Todas las modalidades de jornada" },
    { value: "completa", label: "Jornada completa" },
    { value: "indiferente", label: "Jornada indiferente" },
    { value: "parcial_maniana", label: "Jornada parcial - Turno mañana" },
    { value: "parcial_tarde", label: "Jornada parcial - Turno tarde" },
    { value: "parcial_noche", label: "Jornada parcial - Turno noche" },
    { value: "parcial_indiferente", label: "Jornada parcial - Horario indiferente" },
    { value: "intensiva_maniana", label: "Jornada intensiva - Turno mañana" },
    { value: "intensiva_tarde", label: "Jornada intensiva - Turno tarde" },
    { value: "intensiva_noche", label: "Jornada intensiva - Turno noche" },
    { value: "intensiva_indiferente", label: "Jornada intensiva - Horario indiferente" }
  ]

  const getLabelWorkingDay = (value: string) => {
    const findWorkingDay = optionsWorkingDays.find((item) => item.value === value)
    return findWorkingDay?.label || ''
  }

  const infoSalario = (info_offer: any) => {
    const salario = rex_data_offer?.sal_max && rex_data_offer?.sal_min
      ? `${rex_data_offer.sal_min} - ${rex_data_offer.sal_max}`
      : 'No disponible';

    const periodoInfo = descriptionSalary.find((periodo) => periodo.value === info_offer.abanico_salarial);

    const periodo = info_offer.abanico_salarial ? info_offer.abanico_salarial.charAt(0).toLowerCase() + info_offer.abanico_salarial.slice(1) : ''

    return `Salario: ${salario}€ (${periodoInfo?.label}) (${periodo})`;
  }

  const aptitudes = rex_data_offer?.aptitudes_oferta?.map((aptitudObj: any) => aptitudObj.aptitudes.aptitud) || [];

  const palabras_clave = rex_data_offer?.palabras_clave_oferta?.map((aptitudObj: any) => aptitudObj.palabras_claves.palabra) || [];

  if (!rex_data_offer) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex min-h-screen">
      <Sider
        width={227}
        style={{ backgroundColor: '#fff' }}
        className={` ${inDrawer
            ? "p-[5px] border-none ml-[-10px] mt-[-15px]"
            : "p-[24px] rounded-lg shadow-lg border-2 border-sky-blue0"
          }`}
      >
        <h2 className="font-bold text-heading-md font-sans text-gray mb-[30px]">Descripción de la oferta</h2>
        <div className="mb-[20px]">
          <p className="font-bold text-caption text-gray" onClick={() => { console.log(rex_data_offer) }}>Puesto de trabajo</p>
          <p className="text-caption text-gray">{rex_data_offer.cargo}</p>
          <p className="text-caption text-gray">{rex_data_offer.ubi_provincia + ', ' + rex_data_offer.ubi_poblacion}</p>
          <p className="text-caption text-gray">{infoSalario(rex_data_offer)}</p>
        </div>

        {/* <div className="mb-[20px]">
          <h3 className="font-bold text-caption text-gray">Información sobre el puesto</h3>
          <ul className="text-caption text-gray">
            {jobDetails.map((detail, index) => (
              <h1 key={index}>{detail}</h1>
            ))}
          </ul>
        </div> */}

        <div className="mb-[20px]">
          <h3 className="font-bold text-caption text-gray">Información sobre el puesto</h3>
          <ul className="text-caption text-gray">
            <p className="text-caption text-gray">{rex_data_offer.anios_experiencia + ' ' + ' años de experiencia'}</p>
            {/* <p className="text-caption text-gray">Eso</p> */}
            <p className="text-caption text-gray">{getLabelWorkingDay(rex_data_offer.jornada_laboral)}</p>
            {/* <p className="text-caption text-gray">Mañana y tarde</p> */}
            <p className="text-caption text-gray">{rex_data_offer.sectores?.sector}</p>
          </ul>
        </div>

        <div className="mb-[20px]">
          <h3 className="font-bold text-caption text-gray">Aptitudes / Tecnología</h3>
          <ul className="text-caption text-gray">
            {aptitudes.map((aptitude: any, index: any) => (
              <h1 key={index}>{aptitude}</h1>
            ))}
          </ul>
        </div>

        <div>
          <h3 className="font-bold text-caption text-gray">Palabras clave</h3>
          <ul className="text-caption text-gray">
            {palabras_clave.map((aptitude: any, index: any) => (
              <h1 key={index}>{aptitude}</h1>
            ))}
          </ul>
        </div>
      </Sider>
    </div>
  );
};

export default OfferDescription;
