import React from 'react';
import { Row, Col, Button } from 'antd';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import ImgLogo from '../../assets/logo/logo.png';
import IconEnterprise from '../../assets/icons/enterprise.svg';
import '../../components/styles/pages/principalNav/PrincipalNav.css';
import { useSelector } from 'react-redux';

const CardTopHome: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { rex_user } = useSelector(({ auth }: any) => auth);

  const isCompanyView = location.pathname === '/homeCompany';

  const handleSwitchToCompanyView = () => {
    return navigate('/homeCompany')
  };

  const handleSwitchToHomeView = () => {
    navigate('/');
  };

  const handleSwitchToEmployment = (type:string) => {
    if(rex_user){
      switch (rex_user?.tipos_usuarios?.tipo_usuario) {
        case "Empresa":
          return navigate('/controlPanel');
        case "Candidato":
          return navigate('/employment/recommendations');
        }
    }
    return navigate(type)
  }

  const handleSwitchSearchTalent = () => {
    if(rex_user){
      switch (rex_user?.tipos_usuarios?.tipo_usuario) {
        case "Empresa":
          return navigate('/controlPanel');
        case "Candidato":
          return navigate('/employment/recommendations');
        }
    }

    return navigate('/homeCompany')
  }

  return (
    <div className="principal-nav2">
      <header className="principal-nav-header">
        <div className="flex justify-center">
          <div className="w-full 2x2:w-page-width px-4 lg:px-6">
            <Row justify="space-between" align="middle" className="principal-nav-header-row mb-[1px]">

              <Col xs={24} sm={24} md={9} className="principal-nav-logo-col">
                <Link to="/home">
                  <img src={ImgLogo} alt="Merere Logo" className="" />
                </Link>
              </Col>

              <Col xs={24} sm={24} md={6} className="principal-nav-links-col">
                <nav className="principal-nav-nav2">
                  {isCompanyView ? (
                    <span
                      onClick={handleSwitchSearchTalent}
                      className="principal-nav-link"
                    >
                      Buscar talento
                    </span>
                  ) : (
                    <>
                      <span
                        onClick={()=> handleSwitchToEmployment('/home')}
                        className="principal-nav-link"
                      >
                        Empleo
                      </span>
                      <span
                        onClick={()=> handleSwitchToEmployment('/homeCompany')}
                        className="principal-nav-link"
                      >
                        Empresas
                      </span>
                    </>
                  )}
                </nav>
              </Col>
              <Col xs={24} sm={24} md={9} className="principal-nav-action-buttons-col">


                <Row justify="end" className="principal-nav-action-buttons">


                  <Col
                  // xxl={24}
                  // xl={24}
                  // md={24}
                  >
                    {isCompanyView ? (
                      <>
                        <div
                          style={{
                            // backgroundColor:'red',
                            display: 'flex',
                            // justifyContent:'center',
                            // flexDirection:'column',

                            // display:'flex',
                            // flexDirection:'column',
                            // justifyContent:'center'
                          }}
                        >
                          <Button
                            className="Button-Filter-Employment"
                            style={{ background: '#006497', color: 'white', marginRight: '10px', height: '44px', width: '165px', fontSize: '14px', fontFamily: 'Inter', fontWeight: 600 }}
                            onClick={() => navigate('/login-enterprise')}
                          >
                            ÁREA DE EMPRESA
                          </Button>


                          <Button
                            className="principal-nav-notify-button"
                            style={{ color: '#006497', border: '1px solid #006497', height: '44px', width: '190px', fontSize: '14px', fontFamily: 'Inter', fontWeight: 600 }}
                            onClick={handleSwitchToHomeView}
                          >
                            En búsqueda de empleo
                          </Button>

                        </div>
                      </>

                    ) : (
                      <div className='flex'>
                        <Button
                          className='Button-Filter-Employment'
                          style={{ background: '#006497', color: 'white', marginRight: '10px', height: '44px', width: '175px', fontSize: '14px', fontFamily: 'Inter', fontWeight: 600 }}
                          onClick={() => navigate('/login')}
                        >
                          ÁREA DE CANDIDATO
                        </Button>
                        <Button
                          className="  Button-Filter-Employment"
                          style={{ background: '#006497', color: 'white', height: '44px', width: '165px', fontSize: '14px', fontFamily: 'Inter', fontWeight: 600 }}
                          onClick={handleSwitchToCompanyView}
                        >
                          <img src={IconEnterprise} alt="Soy una empresa" style={{ width: '18px', marginRight: '0px' }} />
                          Soy una empresa
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </header>
    </div>
  );
};

export default CardTopHome;