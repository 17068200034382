import React from 'react'
import { Button, Typography  } from 'antd';
import '../../styles/components/cards/CardStats.css'

interface CardEmpleoProps {
  text: string | number;
  iconButton?: JSX.Element;
  customClassName?: string;
  textEllipsis?: boolean
}

const CardStats: React.FC<CardEmpleoProps> = ({
  text,
  iconButton = null,
  customClassName = '',
  textEllipsis = false
}) => {

  const { Paragraph } = Typography;

  return (
    <Button
      icon={iconButton ? iconButton : null}
      className={`button-stats rounded-full px-2 font-sans py-[2px] ${customClassName}`}
    >
      <Paragraph className='!mb-0 font-medium  text-xs font-sans' ellipsis={textEllipsis}>{text}</Paragraph>
    </Button>
  )
}

export default CardStats