import React, { useState, useEffect } from "react";
import { Modal, Button, Col, Row } from "antd";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store/store";
import { UpdateProfileCompanyReducer } from "../../../../redux/actions/pages/myPortal/profile/ProfileCompany";
import { GetPaisesReducer } from "../../../../redux/actions/pages/emplotment/searchCountry";
import { GetSectorsReducer } from "../../../../redux/actions/common/sectors/Sectors";
import IconClosed from "../../../../assets/icons/IconClosed.svg";
import InputC from "../../../../components/pages/offers/KillerQuestions/ComponentsKillersQ/Input";
import ModalSaved from '../../myPortal/Modals/ModalSavedChanges';
import SelectBasic from "../../offers/CreateOffers/SelectBasic";
import Select from "../../../../components/pages/offers/CreateOffers/Select";

const validationSchema = Yup.object().shape({
  empresa: Yup.string().required('El nombre de la empresa es requerido'),
  pagina_web: Yup.string().required('La página web es requerida'),
  sede_fiscal: Yup.string().required('La sede fiscal es requerida'),
  tamanio: Yup.number()
    .required('El tamaño de la empresa es requerido')
    .min(1, 'Debe ser mayor a 0'),
  ubicacion: Yup.string().required('El país es requerido'),
  ciudad: Yup.string().required('La ciudad es requerida'),
  sector_id: Yup.number().required('El sector es requerido'),
});

interface ModalEditProfileProps {
  visible: boolean;
  onClose: () => void;
  ID_empresa: string;
}

const ModalEditProfileCompany: React.FC<ModalEditProfileProps> = ({
  visible,
  onClose,
  ID_empresa
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { rex_user } = useSelector(({ auth }: any) => auth);
  const { rex_loading, rex_error } = useSelector(({ profileCompany }: any) => profileCompany);
  const paises = useSelector((state: RootState) => state.paises.rex_paises);
  const loading = useSelector((state: RootState) => state.paises.rex_loading);

  const [isSecondModalVisible, setIsSecondModalVisible] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<string | null>("");
  const [availableCities, setAvailableCities] = useState<any[]>([]);
  const [selectedSector, setSelectedSector] = useState<any | null>({
    value: rex_user?.empresa?.sectores?.id || "", // Asegúrate de que el valor sea el ID del sector
    label: rex_user?.empresa?.sectores?.sector || "",
  });
  
  const initialValues = {
    empresa: rex_user?.empresa?.empresa || "",
    pagina_web: rex_user?.empresa?.pagina_web || "",
    sede_fiscal: rex_user?.empresa?.sede_fiscal || "",
    tamanio: rex_user?.empresa?.tamanio || "",
    ubicacion: rex_user?.empresa?.ubicacion || "",
    ciudad: rex_user?.empresa?.ciudad || "",
    sector_id: rex_user?.empresa?.sectores?.id || "",
  };

  useEffect(() => {
    dispatch(GetPaisesReducer());
    dispatch(GetSectorsReducer());
  }, []);

  useEffect(() => {
    if (selectedCountry && paises.length > 0) {
      const country = paises.find((c: any) => c.pais === selectedCountry);
      if (country) {
        setAvailableCities(country.ciudades);
      } else {
        setAvailableCities([]);
      }
    } else {
      setAvailableCities([]);
    }
  }, [selectedCountry, paises]);

  const handleSubmit = async (values: any) => {
    const rpta = await dispatch(UpdateProfileCompanyReducer(values, ID_empresa));
    if (!rex_error) {
      onClose();
      setIsSecondModalVisible(true);
    }
  };

  const fieldConfigs = [
    { name: 'empresa', label: 'Nombre de la Empresa', type: 'text' },
    { name: 'pagina_web', label: 'Página Web', type: 'text' },
    { name: 'sede_fiscal', label: 'Sede Fiscal', type: 'text' },
    { name: 'tamanio', label: 'Tamaño de la Empresa', type: 'number' },
  ];

  return (
    <>
      <Modal
        open={visible}
        onCancel={onClose}
        footer={null}
        centered
        closable={false}
        width={677}
        bodyStyle={{ borderRadius: "12px" }}
        style={{ borderRadius: "12px", border: "1px solid #E1E1E2", marginTop: '15px', marginBottom: '15px' }}
      >
        <img
          src={IconClosed}
          alt="Cerrar"
          onClick={onClose}
          style={{
            position: "absolute",
            top: "34px",
            right: "34px",
            cursor: "pointer",
            width: "24px",
            height: "24px",
          }}
        />
        <div className="text-center mx-[86px] mb-[36px]">
          <h3 className="mt-[16px] mx-[44px] text-heading-md font-bold">
            Editar Información de la Empresa
          </h3>
          <p className="font-medium px-[55px] text-body-sm mt-[10px]">
            Por favor, actualiza la información de tu empresa
          </p>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ handleChange, setFieldValue, values, errors, touched }) => (
            <Form>
              <Row gutter={[16, 16]}>
                {fieldConfigs.map((field) => (
                  <Col xxl={field.name === 'descripcion' ? 24 : 12} xl={12} md={12} key={field.name}>
                    <div>
                      <span className="text-body-md font-medium text-[#5F5F5F]">
                        {field.label}
                      </span>
                      <InputC
                        type={field.type}
                        name={field.name}
                        placeholder={`Ingrese ${field.label.toLowerCase()}`}
                        value={values[field.name as keyof typeof values]}
                        onChange={handleChange}
                        style={{
                          borderRadius: "12px",
                          height: "36px",
                        }}
                      />
                      <ErrorMessage
                        name={field.name}
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                  </Col>
                ))}
              </Row>

              <Row gutter={[16, 16]} className="mt-[20px]">
                <Col xxl={12} xl={12} xs={12}>
                  <p className="text-body-md mb-[2px] font-medium text-[#5F5F5F]">País</p>
                  <SelectBasic
                    placeholder="País"
                    style={{ width: "100%", height: '44px', border: "1px solid #E1E1E2",
                      borderRadius: "8px" }}
                    value={values.ubicacion}
                    onChange={(value) => {
                      setFieldValue("ubicacion", value);
                      setSelectedCountry(value);
                      setFieldValue("ciudad", "");
                    }}
                    loading={loading}
                    options={paises.map((country: any) => ({
                      value: country.pais,
                      label: country.pais,
                    }))}
                  />
                  <ErrorMessage
                    name="ubicacion"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </Col>

                <Col xxl={12} xl={12} xs={12}>
                  <p className="text-body-md mb-[2px] font-medium text-[#5F5F5F]">Ciudad</p>
                  <SelectBasic
                
                    placeholder="Ciudad"
                    style={{ width: "100%", height: '44px', border: "1px solid #E1E1E2",
                      borderRadius: "8px" }}
                    value={values.ciudad}
                    disabled={!selectedCountry}
                    options={availableCities.map((city: any) => ({
                      value: city.ciudad,
                      label: city.ciudad
                    }))}
                    onChange={(value) => setFieldValue("ciudad", value)}
                  />
                  <ErrorMessage
                    name="ciudad"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </Col>
              </Row>

              <Row gutter={[16, 16]} className="mt-5">
                <Col xxl={12} xl={12} xs={12}>
                  <h1 className="text-body-md font-medium text-[#5F5F5F]">Sector</h1>
                  <Select
                    showSearch
                    placeholder="Seleccionar Sector"
                    size="large"
                    value={selectedSector}
                    onChange={(selectedOption: any) => {
                      setFieldValue("sector_id", parseInt(selectedOption.value));
                      setSelectedSector(selectedOption);
                    }}
                    fetchOptions={async (search) => {
                      const response = await dispatch(GetSectorsReducer(search, 1, 5));
                      return response.payload.data.map((sector: any) => ({
                        label: sector.sector,
                        value: sector.id.toString(),
                      }));
                    }}
                    style={{ width: "100%", height: '44px', border: "1px solid #E1E1E2",
                      borderRadius: "8px" }}
                  />
                  <ErrorMessage
                    name="sector_id"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </Col>
              </Row>

              <div className="flex justify-center mt-[40px]">
                <Button
                  onClick={onClose}
                  className="principal-nav-notify-buttonG w-[118px] h-[44px]"
                  style={{ marginRight: "8px", borderRadius: "4px" }}
                >
                  Cancelar
                </Button>
                <Button
                  htmlType="submit"
                  disabled={rex_loading}
                  className={`
                    w-[181px] h-[44px] ml-[18px] rounded-[4px] 
                    ${rex_loading
                      ? 'bg-[#F4F4F5] text-[#757575] font-semibold text-body-md cursor-not-allowed'
                      : 'bg-blue3 principal-nav-notify-button2 text-white cursor-pointer'
                    }
                  `}
                >
                  {rex_loading ? 'Guardando...' : 'Guardar cambios'}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>

      <ModalSaved
        visible={isSecondModalVisible}
        onClose={() => setIsSecondModalVisible(false)}
      />
    </>
  );
};

export default ModalEditProfileCompany;