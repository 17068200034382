import React, { useState, useEffect } from 'react';
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { Col, Modal, Row } from 'antd';
import { useParams } from 'react-router-dom';
import ValuationModal from '../../../../components/pages/modals/ModalValoraPerfil';
import CustomLegend from './Legend';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store/store';
import { updateFasePostulacion } from '../../../../redux/actions/common/fase/PatchApplicationPhases';
import { getCvGeneralByIdReducer } from '../../../../redux/actions/pages/searchCV/GetSearchCV_ID';
import { GetSoftSkillsByIdReducer } from '../../../../redux/actions/pages/offers/candidate/softSkills/GetSoftSkillsID';
import { GetPostulacionesByOfertaReducer } from '../../../../redux/actions/pages/offers/offersProfile/GetOffersProfile';
import { GetPhasePostulationOfferOfferReducer, GetSoftSkillsOfferReducer } from '../../../../redux/actions/pages/company/Offers';
import { DetailSoftSkillsOfferFormat } from '../../../../constants/pages/searchCV/GetSearchCV';
import IconCheck from '../../../../assets/icons/checkWhite.svg';
import ProfileLocked from '../../../../assets/img/profile/ProfileLocked.png';
import ButtonUnlocked from './ButtonUnlocked';


const faseMapping = {
  "No Revisado": { id: 1, color: "text-black bg-white" },
  "Descartado": { id: 2, color: "bg-[#FF3B30] text-white" },
  "Preseleccionado": { id: 3, color: "bg-[#1C82BC] text-white" },
  "Entrevista": { id: 4, color: "bg-[#FF9500] text-white" },
  "Seleccionado": { id: 5, color: "bg-[#34C759] text-white" },
};

const IMAGE_USER_DEFAULT = process.env.REACT_APP_IMAGE_USER_DEFAULT;
const API_BASE_URL_EXACT = process.env.REACT_APP_API_BASE_URL_EXACT;
interface Skill {
  subject: string;
  A: number;
  B: number;
}

interface PostulacionType {
  id: number;
  usuarios: { personas: { nombre: string; apellido_paterno: string; apellido_materno: string } };
  fases_postulaciones?: { fase: string };
}

interface UserSoftSkillsDto {
  nivel: any;
  soft_skills: {
    soft_skill: string
  }
}

interface UserDto {
  soft_skills_usuarios: UserSoftSkillsDto[]
}

interface DetailSoftSkillsFormat {
  soft_skills: {
    soft_skill: any
    soft_skill_c: any
  }
  nivel: number
}

interface CandidateInfoProps {
  imageUrl: string;
  nombre: string;
  puesto: string;
  email: string;
  ubicacion: string;
  habilidades: string[];
  descripcion: string;
  radarData: Skill[];
  isProfileUnlocked: boolean;
  postulaciones: PostulacionType[];
  isViewOffer: boolean;
  updateSuccess: boolean;
  unLockedProfile: () => void
}

const CandidateInfo: React.FC<CandidateInfoProps> = ({
  imageUrl,
  puesto,
  habilidades,
  descripcion,
  radarData,
  isProfileUnlocked,
  postulaciones,
  isViewOffer,
  updateSuccess,
  unLockedProfile
}) => {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newFase, setNewFase] = useState('');
  const [selectedPostulacionId, setSelectedPostulacionId] = useState<number | null>(null);

  const { candidateId, id, postulacionId } = useParams<{ candidateId: string, postulacionId: string; id: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const { cvGeneralById, loading, error } = useSelector((state: RootState) => state.getSearchIDCV);
  const { softSkills, loading: softSkillsLoading, error: softSkillsError } = useSelector((state: RootState) => state.getSoftSkillsID);

  const {
    rex_soft_skills_offers: rex_soft_skills_offers,
    rex_phase_postulation_offer: rex_phase_postulation_offer
  } = useSelector(({ offers }: any) => offers);

  const { rex_aptitudes } = useSelector(
    (state: RootState) => state.getAptitud
  )

  useEffect(() => {
    if (candidateId) {
      dispatch(getCvGeneralByIdReducer(parseInt(candidateId, 10)));
      dispatch(GetSoftSkillsByIdReducer(parseInt(candidateId, 10)));
    }
    if (id) {
      dispatch(GetSoftSkillsOfferReducer(id))
      dispatch(GetPhasePostulationOfferOfferReducer(candidateId, postulacionId))
    }
  }, []);

  useEffect(() => {
    if (postulacionId) {
      dispatch(GetPhasePostulationOfferOfferReducer(candidateId, postulacionId))
    }
  }, [updateSuccess])

  useEffect(() => {
    if (candidateId) {
      dispatch(GetPostulacionesByOfertaReducer(parseInt(candidateId, 10)));
    }
  }, [candidateId]);

  // Asegúrate de que cvGeneralById exista
  if (!cvGeneralById || !cvGeneralById.data) {
    return <div>No se encontró el candidato</div>;
  }

  const handleOpenModal = (fase: string, postulacionId: number) => {
    setNewFase(fase);
    setSelectedPostulacionId(postulacionId);
    setIsModalVisible(true);
  };

  const handleConfirmChange = () => {
    if (selectedPostulacionId) {
      handleChangeFase(newFase, selectedPostulacionId);
    }
    setIsModalVisible(false);
  };

  const handleChangeFase = async (newFase: string, postulacionId: number) => {
    const fasePostulacionId = faseMapping[newFase as keyof typeof faseMapping].id;
    if (postulacionId && fasePostulacionId) {
      await dispatch(updateFasePostulacion(postulacionId, fasePostulacionId));
      // Recargar postulaciones para reflejar cambios en UI
      dispatch(GetPostulacionesByOfertaReducer(parseInt(candidateId ?? "0", 10))); // Aseguramos pasar el id necesario
    }
  };

  const handleFaseChange = (value: string, postulacionId: string) => {
    handleOpenModal(value, +postulacionId);
  };

  const renderModal = () => (
    <Modal
      title="Confirmación"
      visible={isModalVisible}
      onOk={handleConfirmChange}
      onCancel={() => setIsModalVisible(false)}
      okText="Sí, cambiar fase"
      cancelText="Cancelar"
    >
      <p>¿Estás seguro de que deseas cambiar a la fase "{newFase}"?</p>
    </Modal>
  );

  const handleUnlockProfile = () => {
    unLockedProfile()
  };


  if (loading) return <div>Cargando...</div>;
  if (error) return <div>Error: {error}</div>;

  const candidato = cvGeneralById.data;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const defaultSoftSkills = [
    { subject: 'Adaptable', A: 0, B: 0, fullMark: 10 },
    { subject: 'Consistente', A: 0, B: 0, fullMark: 10 },
    { subject: 'Detallista', A: 0, B: 0, fullMark: 10 },
    { subject: 'Innovador', A: 0, B: 0, fullMark: 10 },
    { subject: 'Metódico', A: 0, B: 0, fullMark: 10 },
    { subject: 'Proactivo', A: 0, B: 0, fullMark: 10 },
    { subject: 'Resiliente', A: 0, B: 0, fullMark: 10 },
    { subject: 'Visionario', A: 0, B: 0, fullMark: 10 },
  ];

  const formatDataSoftsKill = (data: any) => {

    if (data?.length > 0) {

      let dataFormated: any[]
      if (isViewOffer) {
        dataFormated = rex_soft_skills_offers.flatMap((item: DetailSoftSkillsOfferFormat, index: number) => {

          const findSubjectUser = data.find((usr: UserSoftSkillsDto) => usr.soft_skills.soft_skill == item.soft_skills.soft_skill)

          return [
            {
              subject: item.soft_skills.soft_skill,
              A: item.porcentaje > 0 ? item.porcentaje / 10 : 0,
              B: findSubjectUser ? findSubjectUser.nivel : 0,
              fullMark: 10,
              index: index
            },
            {
              subject: item.soft_skills.soft_skill_c,
              A: 10 - (item.porcentaje > 0 ? item.porcentaje / 10 : 0),
              B: findSubjectUser ? 10 - (findSubjectUser.nivel) : 0,
              fullMark: 10,
              index: index
            }
          ]
        })
      } else {
        dataFormated = data.flatMap((item: DetailSoftSkillsFormat) => [
          {
            subject: item.soft_skills.soft_skill,
            B: item.nivel,
            fullMark: 10
          },
          {
            subject: item.soft_skills.soft_skill_c,
            B: 10 - item.nivel,
            fullMark: 10
          }
        ]
        )
      }

      if (dataFormated.length == 0) {
        return defaultSoftSkills
      } else {
        return dataFormated.sort((a: any, b: any) => {
          if (a.subject < b.subject) return -1
          if (a.subject > b.subject) return 1
          return 0
        })
      }
    } else {
      return defaultSoftSkills
    }
  }

  return (
    <Row className="">
      <Col xs={24} md={10} lg={12} className="">
        <div className="flex flex-col items-start">
          <h2 className="text-[22px] font-bold mb-5 font-sans">Perfil</h2>
          <div
            className={`relative ${isProfileUnlocked ? '' : 'border border-[#006497] rounded-md p-6'}`}
          >
            {!isProfileUnlocked && !isViewOffer ? (
              <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center z-10">
                <ButtonUnlocked
                  text='Desbloquear perfil'
                  onAction={handleUnlockProfile}
                />
              </div>
            ) : null}

            {
              isProfileUnlocked || isViewOffer
                ? <div className="flex flex-row md:flex-col lg:flex-col 2xl:flex-row items-center mb-4">
                  <img
                    src={candidato.imagen ? `${API_BASE_URL_EXACT}${candidato.imagen}` : IMAGE_USER_DEFAULT}
                    alt="Perfil"
                    className="w-[160px] h-[160px] mr-4"
                    style={{
                      borderRadius: '4px',
                      objectFit: 'cover'
                    }}
                  />

                  <div>

                    {
                      isViewOffer
                        ? <div
                          className={`flex items-center justify-center -top-2 text-center rounded-full px-3 text-white font-bold text-[12px] h-[22px] w-[127px] appearance-none ${rex_phase_postulation_offer?.data?.fases_postulaciones?.fase === "Descartado"
                            ? "bg-[#FF3B30]"
                            : rex_phase_postulation_offer?.data?.fases_postulaciones?.fase === "Preseleccionado"
                              ? "bg-[#1C82BC]"
                              : rex_phase_postulation_offer?.data?.fases_postulaciones?.fase === "Entrevista"
                                ? "bg-[#FF9500]"
                                : rex_phase_postulation_offer?.data?.fases_postulaciones?.fase === "Seleccionado"
                                  ? "bg-[#34C759]"
                                  : "text-black"
                            }`}
                          style={rex_phase_postulation_offer?.data?.fases_postulaciones?.fase === "No Revisado" ? { zIndex: 11, color: 'black' } : { zIndex: 11 }}
                        >
                          {rex_phase_postulation_offer?.data?.fases_postulaciones?.fase}
                        </div>
                        : null
                    }

                    {renderModal()}

                    {/* Nombre completo del candidato */}
                    <h2
                      className="text-lg font-sans font-regular"
                      onClick={() => console.log(candidato)}
                    >
                      {`${candidato.personas.nombre} ${candidato.personas.apellido_paterno} ${candidato.personas.apellido_materno}`}
                    </h2>
                    {/* Cargo y ubicación */}
                    <p className="text-base text-gray-500 font-bold">{candidato.cargo}</p>
                    <p className="text-base text-gray-500 font-sans">{candidato.email}</p>
                    {/* <p className="text-base text-gray-500">{candidato.ubicacion}</p> */}
                    <p className="text-base font-medium font-sans text-gray-500 mb-2">{
                      candidato.pais && candidato?.pais?.pais + " | " + candidato?.ciudad
                    }</p>
                    <ButtonUnlocked
                      icon={<img src={IconCheck} alt='Icon check' />}
                      text='Valorar candidato'
                      onAction={showModal}
                      customClassName={'md:w-[211px]'}
                    />
                    {/* <button
                      className="principal-nav-notify-button border border-blue-500 px-4 py-2 rounded"
                      onClick={showModal}
                    >
                      Valorar candidato
                    </button> */}
                  </div>
                </div>
                : <div className='w-20 md:w-80'>
                  <img
                    src={ProfileLocked}
                    alt="Perfil"
                    className="w-full h-40 mr-4"
                  />
                </div>
            }


          </div>

          <div className="mt-4">
            <h3 className="font-bold text-lg font-sans">Aptitudes</h3>
            <div className="flex flex-wrap gap-2 mt-2">
              {
                rex_aptitudes?.map((apt, index) => (
                  <span
                    key={index}
                    className="bg-[#FCFCFC] border border-[#E1E1E2] rounded-full px-3 py-1 text-[16px] font-semibold text-black"
                  >
                    {apt.aptitudes.aptitud}
                  </span>

                ))
              }
            </div>
          </div>

          <div className="mt-[32px]">
            <h3 className="font-bold font-sans mb-2 text-lg">Sobre mí</h3>
            <p className="text-[#666666] font-sans font-medium text-[16px] overflow-hidden" style={{ display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical', whiteSpace: 'normal', textOverflow: 'ellipsis' }}>
              {candidato.sobreMi}
            </p>
          </div>
        </div>
      </Col>

      <Col xs={24} md={14} lg={12}>
        <h3 className="font-bold text-lg text-right">Soft skills</h3>
        <div className="mt-8 flex justify-end">
          <div style={{ width: '100%', height: '100%',  display:'flex', justifyContent:'end', zIndex: 2 }}>
            <ResponsiveContainer width="100%" height={350}>
              <RadarChart
                cx="50%" cy="50%"
                outerRadius="80%"
                data={formatDataSoftsKill(softSkills)}
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              >
                <PolarGrid />
                <PolarAngleAxis
                  dataKey="subject"
                  tick={{ fontSize: 12, fill: '#000', fontWeight: 'bold' }}
                />
                <PolarRadiusAxis axisLine={false} tick={false} tickCount={11} angle={30} domain={[0, 10]} />
                <Radar name="Perfil del Candidato" dataKey="B" stroke="#006497" fill="transparent" />
                {
                  isViewOffer && <Radar name="Perfil de la oferta" dataKey="A" stroke="#FDBCB4" fill="transparent" />
                }
                <Legend content={<CustomLegend customClassName='-mt-[40px]' showOffer={isViewOffer} />} />
                <Tooltip />
              </RadarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </Col>

      <ValuationModal
        visible={isModalVisible}
        onClose={closeModal}
        entityName={candidato.personas.nombre}
        entityType="candidate"
        linkVal='PENDIENTE'
      />
    </Row>
  );
};

export default CandidateInfo;
