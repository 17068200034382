import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Carousel, Button, Menu, Dropdown, Modal, Row, Col } from 'antd';
import CandidateInfo from "../../../components/pages/offers/offersProfile/CandidateInfo";
import LaboralExp from "../../../components/pages/offers/offersProfile/LaboralExp";
import Portfolio from "../../../components/pages/offers/offersProfile/Portfolio";
import RatingBlue from "../../../components/rating/RatingBlue";
import Avatar1 from "../../../assets/img/offers/Avatar.png";
import icon from "../../../assets/img/offers/ArrowLeft.svg";
import ModalConfirm from '../../../components/pages/offers/offersProfile/ModalConfirm';
import CandidateBaner from '../../../components/pages/offers/offersProfile/CandidateBaner'
import CarouselReviews from '../../../components/carousels/CarouselReviews';
import { AppDispatch, RootState } from '../../../redux/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { GetUserRatingsReducer } from '../../../redux/actions/pages/searchCV/GetRatings';
import { getCvGeneralByIdReducer } from '../../../redux/actions/pages/searchCV/GetSearchCV_ID';
import { GetSoftSkillsByIdReducer } from '../../../redux/actions/pages/offers/candidate/softSkills/GetSoftSkillsID';
import { GetUserCVByIdReducer } from '../../../redux/actions/pages/searchCV/cv/GetCVSearch';
import { GetUserPortfolioByIdReducer } from '../../../redux/actions/pages/searchCV/portfolio/GetPortfolioUser';
import { fetchApplicationPhasesReducer } from "../../../redux/actions/common/fase/GetApplicationPhases";
import { updateFasePostulacion } from '../../../redux/actions/common/fase/PatchApplicationPhases';
import { ConsumeCreditReducer, GetShowProfileReducer } from '../../../redux/actions/common/paquetesUsuarios/PaquetesUsuarios';
import ModalDesbloquearPerfil from '../../../components/modals/ModalDesbloquearPerfil';
import ButtonUnlocked from '../../../components/pages/offers/offersProfile/ButtonUnlocked';
import { GetAptitudByIdReducer } from '../../../redux/actions/pages/myPortal/aptitudes/GetAptitud';

interface CandidateData {
  id: number;
  imageUrl: string;
  puesto: string;
  email: string;
  habilidades: string[];
  descripcion: string;
  radarData: { subject: string; A: number; B: number }[];
  education: {
    title: string;
    institution: string;
    location: string;
    startDate: string;
    endDate: string;
    totalDuration: string;
    description: string;
    ratings: string;
    tags: string[];
  }[];
  languages: {
    name: string;
    level: string;
  }[];
  portfolio: {
    title: string;
    projects: {
      title: string;
      description: string;
      skills: string[];
      website?: string;
      mediaLinks: string[];
    }[];
  };
  reviews: {
    name: string;
    jobTitle: string;
    rating: number;
    pros: string;
    cons: string;
    review: string;
    avatarUrl: string;
    timeAgo: string;
  }[];
  postulaciones?: PostulacionType[];
}

type PostulacionType = {
  id: number;
  usuarios: { personas: { nombre: string; apellido_paterno: string; apellido_materno: string } };
  fases_postulaciones?: { fase: string };
}

const CandidateInformation: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const { id, postulacionId, candidateId } = useParams<{ id: string; postulacionId: string; candidateId: string }>();

  const [isProfileUnlocked, setIsProfileUnlocked] = useState(false);
  const [showUnlockedProfile, setShowUnlockedProfile] = useState<boolean>(false)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedFaseId, setSelectedFaseId] = useState<number | null>(null);

  const { rex_phases, rex_loadingp: phasesLoading } = useSelector((state: RootState) => state.getApplicationPhases);
  const { loading: updateLoading, error: updateError, success: updateSuccess } = useSelector((state: RootState) => state.patchApplicationPhases);

  const {
    rex_userRatings,
    rex_loading,
    rex_meta_userRatings
  } = useSelector(({ getRatings }: any) => getRatings);


  const {
    rex_show_profile
  } = useSelector(({ paquetesUsuarios }: any) => paquetesUsuarios);

  useEffect(() => {
    if (candidateId) {
      dispatch(GetShowProfileReducer(+candidateId))
      dispatch(GetAptitudByIdReducer(+candidateId))
    }
  }, [])

  const { rex_userCVById, rex_loading: cvLoading, rex_error: cvError } = useSelector((state: RootState) => state.getCvSearch);
  const { rex_userPortfolioById, rex_loading: cv_loading, rex_error } = useSelector((state: RootState) => state.getPortfolioUser);

  const handleUnlockProfile = () => {
    setShowUnlockedProfile(true)
  };

  const candidateData: { [key: number]: CandidateData } = {
    1: {
      id: 1,
      imageUrl: Avatar1,
      puesto: "Reponedor",
      email: "email",
      habilidades: ["Office", "Informática", "Dependiente con experiencia"],
      descripcion:
        "Soy un entusiasta del mundo digital con una pasión por transformar datos en decisiones estratégicas. Con más de 5 años de experiencia en análisis de datos y machine learning...",
      radarData: [
        { subject: "Comunicación", A: 120, B: 110 },
        { subject: "Trabajo en equipo", A: 98, B: 130 },
        { subject: "Liderazgo", A: 86, B: 130 },
        { subject: "Creatividad", A: 99, B: 100 },
        { subject: "Adaptabilidad", A: 85, B: 90 },
      ],
      education: [
        {
          title: "Bachillerato de arte",
          institution: "Instituto de Educación Secundaria",
          location: "Alcazar de San Juan",
          startDate: "Septiembre 2016",
          endDate: "Junio 2018",
          totalDuration: "2 años",
          description:
            "Estudios enfocados en historia del arte, dibujo y diseño.",
          ratings: "4.7/5",
          tags: ["Arte", "Historia", "Dibujo"],
        },
      ],
      languages: [
        {
          name: "Inglés",
          level: "B2",
        },
        {
          name: "Español",
          level: "Nativo",
        },
      ],
      portfolio: {
        title: "Portafolio",
        projects: [
          {
            title: "Branding | Congreso Internacional de Género",
            description:
              "Logotipo, imagen corporativa y voz y tono del proyecto",
            skills: ["Branding", "Género", "Logotipo", "Imagen corporativa"],
            website: "https://www.congresogeneroyeducacion.com",
            mediaLinks: [
              "https://www.congresogeneroyeducacion.com/images",
              "https://www.congresogeneroyeducacion.com/videos",
            ],
          },
          {
            title: "Título del proyecto",
            description: "Descripción del proyecto",
            skills: ["Habilidades", "Sitio web"],
            mediaLinks: [
              "https://www.example.com/images",
              "https://www.example.com/videos",
            ],
          },
        ],
      },
      reviews: [
        {
          name: "Elena R.",
          jobTitle: "Vendedor textil",
          rating: 3.4,
          pros: "Teletrabajo. Gestión del horario.",
          cons: "No enriquecerte de un gran equipo de trabajo.",
          review:
            "100% recomendable, E*** y Oscar unos crack. Pero el mayor punto a destacar la comunidad. Poder hablar con gente que está en una situación igual o parecida ayuda mucho a motivarse y seguir aprendiendo.",
          avatarUrl: "/path/to/avatar.jpg",
          timeAgo: "Hace 3 semanas",
        },
      ],
      postulaciones: [],
    },
  };

  useEffect(() => {
    console.log("Valores actuales de los parámetros:", id, postulacionId, candidateId);
  }, [id, postulacionId, candidateId]);

  const isViewOffer = location.pathname.includes('/offer/')

  const candidateInfo = candidateData[1];

  useEffect(() => {
    const isFromOffers = location.pathname.includes('/offer/');
    setIsProfileUnlocked(isFromOffers);

    if (id && postulacionId && candidateId) {
      const offerId = parseInt(id, 10)
      const postulacionIdNum = parseInt(postulacionId, 10)
      const userId = parseInt(candidateId, 10)
      dispatch(GetUserRatingsReducer(userId))
      dispatch(getCvGeneralByIdReducer(userId))
      dispatch(GetSoftSkillsByIdReducer(userId))
      dispatch(GetUserCVByIdReducer(userId))
      dispatch(GetUserPortfolioByIdReducer(userId))
      dispatch(fetchApplicationPhasesReducer())
    }

    if(candidateId){
      dispatch(getCvGeneralByIdReducer(+candidateId))      
    }
  }, [id, postulacionId, candidateId, location.pathname]);

  useEffect(() => {
    if (updateSuccess) {
      // Aquí puedes manejar el éxito de la actualización, por ejemplo, mostrando un mensaje
      console.log('Fase de postulación actualizada con éxito');
    }
  }, [updateSuccess]);


  const handleViewCVData = () => {
    console.log("ID del candidato:", candidateId);
    console.log("Datos del CV del usuario:", rex_userCVById);
    console.log("Estado de carga:", cvLoading);
    console.log("Error (si existe):", cvError);
  };

  const handleViewPortfolioData = () => {
    console.log("ID del usuario:", candidateId);
    console.log("Datos del portafolio del usuario:", rex_userPortfolioById);
    console.log("Estado de carga:", cv_loading);
    console.log("Error (si existe):", rex_error);
  };

  const handlePhaseChange = (phaseId: number) => {
    setSelectedFaseId(phaseId);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const phaseMenu = (
    <Menu
      onClick={({ key }) => {
        handlePhaseChange(Number(key))
      }}
    >
      {rex_phases.map((phase) => (
        <Menu.Item key={phase.id}>{phase.fase}</Menu.Item>
      ))}
    </Menu>
  );

  const handleModalConfirm = () => {
    if (postulacionId && selectedFaseId) {
      dispatch(updateFasePostulacion(parseInt(postulacionId, 10), selectedFaseId));
    }
    setIsModalVisible(false);
  };

  return (
    <div className='flex justify-center'>
      <div className='w-full 2x2:w-page-width px-4 lg:px-8'>
        <div className="mt-4 font-sans">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center text-[#006497] text-[16px] font-bold"
          >
            <img src={icon} alt="Arrow Left" className="mr-2" />
            Volver al listado
          </button>

          <div className="mt-4" style={{ height: "293px" }}>
            <div
              style={{
                backgroundColor: "#81BFEC",
                height: "100%",
                borderRadius: "6px",
              }}
            >
              <CandidateBaner />
            </div>
          </div>

          <div className="flex justify-between items-center mt-6 -mb-2">
            <Row gutter={[0, 6]} className='flex justify-between w-full'>
              <Col xl={20}>
                <Row gutter={[6, 6]} className='h-full flex items-center'>
                  <Col className='flex content-center w-full md:w-auto h-min'>
                    <span className="text-[#1A1A1A] text-[20px] font-normal opacity-70 flex items-center justify-center font-sans">
                      Acciones de perfil:
                    </span>
                  </Col>
                  <Col className='flex content-center justify-center w-2/4 md:w-auto border-r-[3px] border-black h-min'>
                    <div 
                      // className="text-[#00476D] font-bold text-[16px] px-2 flex items-center justify-center font-sans"
                      className="text-green32 font-bold text-[16px] px-2 flex items-center justify-center font-sans cursor-not-allowed"
                    >
                      Añadir a favoritos
                    </div>
                  </Col>
                  {
                    isViewOffer
                      ? <Col className='flex content-center justify-center border-none md:border-solid w-2/4 md:w-auto border-r-[3px] md:border-black h-min'>
                        <Dropdown overlay={phaseMenu} trigger={['click']} disabled={phasesLoading}>
                          <a className="text-[#00476D] font-bold text-[16px] cursor-pointer px-2 flex items-center justify-center font-sans">
                            Avanzar en la fase
                          </a>
                        </Dropdown>
                      </Col>
                      : null
                  }
                  <Col className='flex content-center justify-center w-2/4 md:w-auto border-r-[3px] border-black h-min'>
                    <div 
                      // className="text-[#00476D] font-bold text-[16px] px-2 flex items-center justify-center font-sans"
                      className="text-green32 font-bold text-[16px] px-2 flex items-center justify-center font-sans cursor-not-allowed"
                    >
                      Guardar perfil
                    </div>
                  </Col>
                  <Col className='flex content-center justify-center w-2/4 md:w-auto h-min'>
                    <div 
                      // className="text-[#DC2828] font-bold text-[16px] px-2 flex items-center justify-center font-sans"
                      className="text-green32 font-bold text-[16px] px-2 flex items-center justify-center font-sans cursor-not-allowed"
                    >
                      Archivar candidato
                    </div>
                  </Col>
                </Row>
              </Col>


              <Col xs={24} xl={4} className='flex justify-end'>
                {!rex_show_profile.status && !isViewOffer ? (
                  <ButtonUnlocked
                    text='Desbloquear perfil'
                    onAction={handleUnlockProfile}
                  />
                ) : null}

                {rex_show_profile.status || isViewOffer ? (
                  <ButtonUnlocked
                    text='Comenzar Chat con el candidato'
                    onAction={() => { }}
                  />
                ) : null}
              </Col>
            </Row>
          </div>
        </div>

        <div
          style={{
            border: "1px solid #81BFEC",
            borderRadius: "6px",
            padding: "16px",
            marginTop: "16px",
            background: '#FBFDFE'
          }}
        >
          <CandidateInfo
            imageUrl={candidateInfo.imageUrl}
            nombre="" // Este valor se obtendrá internamente en CandidateInfo
            puesto={candidateInfo.puesto}
            email={candidateInfo.email}
            ubicacion="" // Este valor se obtendrá internamente en CandidateInfo
            habilidades={candidateInfo.habilidades}
            descripcion={candidateInfo.descripcion}
            radarData={candidateInfo.radarData}
            isProfileUnlocked={rex_show_profile.status}
            postulaciones={candidateInfo.postulaciones || []}
            isViewOffer={isViewOffer}
            updateSuccess={updateSuccess}
            unLockedProfile={candidateId ? () => { setShowUnlockedProfile(true) } : () => { }}
          />

          <div className="mt-6">
            <div style={{ display: "flex" }}>
              <h2 className="text-lg font-semibold font-sans">Valoraciones</h2>
              <div
                className="flex items-center mt-2"
                style={{
                  marginTop: "-5px",
                  marginLeft: "10px",
                }}
              >
                <RatingBlue
                  filledStars={rex_userRatings?.valoracion}
                  filledStarSize={{ width: '24px', height: '24px', marginRight: '2px', marginTop: '2px' }}
                  emptyStarSize={{ width: '24px', height: '24px', marginLeft: '-2px' }}
                />
                <span className="ml-2 text-sm text-[#006497] font-sans h-full flex items-center">{rex_meta_userRatings?.total} valoraciones</span>
              </div>
            </div>

            <CarouselReviews
              border={false}
              data_reviews={rex_userRatings?.valoraciones_usuarios}
              loading={rex_loading}
            />
          </div>

          <LaboralExp />
        </div>

      </div>

      {
        candidateId
        && <ModalDesbloquearPerfil
          showModal={showUnlockedProfile}
          setShowModal={setShowUnlockedProfile}
          id={candidateId}
        />
      }

      <ModalConfirm
        visible={isModalVisible}
        onClose={handleModalClose}
        onConfirm={handleModalConfirm}
        loading={updateLoading}
        error={updateError ? updateError : undefined}
      />
    </div>
  );
};

export default CandidateInformation;