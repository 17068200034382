import React, { useEffect, useRef, useState } from "react";
import { Layout, Input, Divider } from "antd";
import "tailwindcss/tailwind.css";
import RatingBlue from "../../rating/RatingBlue";
import ButtonCom from "../../button/Button";
import StyledCheckbox from "../../checkbox/CheckboxProps";
import ToggleSwitch from "../../toggleSwitch/ToggleSwitch";
import Select from "../../../components/pages/searchCV/Select";
import Slider from '../searchCV/Slider';
import IconDrop from "../../../assets/icons/ArrowDrop.svg";
import IconDrop2 from "../../../assets/icons/ArrowDrop2.svg";
import IconArrow from "../../../assets/icons/arrowIconBlue.svg";
import IconLine from '../../../assets/icons/ArrowA.svg';
import IconLine2 from '../../../assets/icons/ArrowLine.svg';
import Keywords from "../employment/searchResult/ComponentsSidebar/KeyWord";
import Company from './ComponentsSidebar/CompanyC';
import Sector from '../employment/searchResult/ComponentsSidebar/Sectors';
import Education from './ComponentsSidebar/Education'
import { GetPaisesReducer } from "../../../redux/actions/pages/emplotment/searchCountry";
import { AppDispatch } from "../../../redux/store/store";
import { useDispatch, useSelector } from "react-redux";
import { GetCiudadesReducer } from "../../../redux/actions/pages/emplotment/searchCities";
import { GetAptitudesReducer } from "../../../redux/actions/common/skills/Skills";

const { Sider } = Layout;
const { Option } = Select;

interface SoftSkillFiltro {
  nivel : number,
  soft_skill : string,
  key : string
}

interface FilterParams {
  educations?: string[];
  ratings?: number[];
  keywords?: string[];
  sectors?: string[];
  experiences?: number[];
  tamanioArray?: { desde: number; hasta: number }[];
  valoraciones_usuariosmax?: boolean;
  paises?: string[];
  ciudades?: string[];
  softSkills?: string[];
  experienciaLaboral?: string[];
  excluirEducationsUsers?: string[];
  excluirExperienciaLaboral?: string[];
  companies?: string[];
  modalidad?: string | null;
  jornada?: string[];
  excluirEmpresa?: string[],
  softSkillsFiltro? : SoftSkillFiltro[]
}

interface SidebarSearchProps {
  onFilterChange?: (filters: Partial<FilterParams>) => void;
  inDrawer?: boolean;
  currentFilters?: FilterParams;
  activeSearch?: boolean,
  setActiveSearch?: any,
  resetFilters?:any
}

const ratings = [0, 1, 2, 3, 4, 5];

const availability = [
  { value: "todas", label: "Todas las modalidades de jornada" },
  { value: "completa", label: "Jornada completa" },
  { value: "indiferente", label: "Jornada indiferente" },
  { value: "parcial_maniana", label: "Jornada parcial - Turno mañana" },
  { value: "parcial_tarde", label: "Jornada parcial - Turno tarde" },
  { value: "parcial_noche", label: "Jornada parcial - Turno noche" },
  { value: "parcial_indiferente", label: "Jornada parcial - Horario indiferente" },
  { value: "intensiva_maniana", label: "Jornada intensiva - Turno mañana" },
  { value: "intensiva_tarde", label: "Jornada intensiva - Turno tarde" },
  { value: "intensiva_noche", label: "Jornada intensiva - Turno noche" },
  { value: "intensiva_indiferente", label: "Jornada intensiva - Horario indiferente" }
];

const Sidebar: React.FC<SidebarSearchProps> = ({
  onFilterChange = () => { },
  inDrawer = false,
  currentFilters = {},
  activeSearch,
  setActiveSearch,
  resetFilters
}) => {
  const [openSections, setOpenSections] = useState({
    valoraciones: false,
    educacion: false,
    disponibilidad: false,
    experiencia: false,
    palabrasClave: true,
    cualificativos: false,
    ubicacion: false,
    busquedasSugeridas: false,
    moreThan10Ratings: false
  });

  const [excludeKeywords, setExcludeKeywords] = useState<string[]>([]);
  const [newExcludeKeyword, setNewExcludeKeyword] = useState<string[]>([]);
  const [modalidad, setModalidad] = useState<boolean>(false)
  const [selectedEducations, setSelectedEducations] = useState<string[]>([]);
  const [selectedExperiences, setSelectedExperiences] = useState<number[]>([]);
  const [selectedRatings, setSelectedRatings] = useState<number[]>([]);
  const [moreThan10Ratings, setMoreThan10Ratings] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [selectedCitys, setSelectedCitys] = useState<string[]>([]);
  const [searchTermCitys, setSearchTermCitys] = useState<string>('');
  const [searchTermCountries, setSearchTermCountries] = useState<string>('');
  const [searchTermSoftSkills, setSearchTermSoftSkills] = useState<string>('');
  const [selectedSoftSkills, setSelectedSoftSkills] = useState<string[]>([]);
  const [filteredCities, setFilteredCities] = useState<[] | null>([]);
  const [selectedAvailability, setSelectedAvailability] = useState<string[]>([])
  const [excludedCountries, setExcludedCountries] = useState<string[]>([])
  const [selectedCurrentCountrie, setSelectedCurrentCountrie] = useState<string | null>(null)
  const [resetKeywords, setResetKeywords] = useState(false)
  const [resetSectors, setResetSectors] = useState(false)
  const [resetEducation, setResetEducation] = useState(false)
  const [resetExperience, setResetExperience] = useState(false)
  const [ selectedSoftSkillsFilter, setSelectedSoftSkillsFilter ] = useState<any[]>([])

  const companyCRef = useRef<any>(null)

  const dispatch = useDispatch<AppDispatch>();
  const {
    rex_paises
  } = useSelector(({ paises }: any) => paises);

  const {
    rex_ciudades
  } = useSelector(({ ciudades }: any) => ciudades);

  const {
    rex_aptitudes
  } = useSelector(({ softskill }: any) => softskill);

  useEffect(() => {
    dispatch(GetPaisesReducer());
    dispatch(GetCiudadesReducer());
    dispatch(GetAptitudesReducer());
  }, [])

  const resetFiltersSelected = () => {
    setSelectedRatings([])
    setMoreThan10Ratings(false)
    setSelectedSoftSkills([])
    setSelectedCountries([])
    setSelectedCitys([])
    setSearchTermCitys('')
    setSearchTermCountries('')
    setSelectedSoftSkillsFilter([])
    setSelectedAvailability([])
    setModalidad(false)
    setFilteredCities([])
    setExcludedCountries([])
    setSelectedCurrentCountrie(null)
    handleSectorsChange([])
    resetCompaniesFilter()
    setResetKeywords(true)
    setResetExperience(true)
    setResetSectors(true)
    setResetEducation(true)
    setActiveSearch(true)
    setTimeout(() => setResetKeywords(false), 100)
    setTimeout(() => setResetSectors(false), 100)
    setTimeout(() => setResetExperience(false), 100)
    setTimeout(() => setResetEducation(false), 100)
  } 

  const resetCompaniesFilter = () => {
    if (companyCRef.current) {
      companyCRef.current.resetCompanies()
    }
  }

  const handleValueChange = (values: number[]) => {
    setSelectedExperiences(values);
    onFilterChange?.({ experiences: values });
  };

  const handleRatingSelect = (value: number) => {
    const newRatings = selectedRatings.includes(value)
      ? selectedRatings.filter(r => r !== value)
      : [...selectedRatings, value];

    setSelectedRatings(newRatings);
    onFilterChange?.({ ratings: newRatings });
  };

  const handleMoreThan10RatingsChange = (checked: boolean) => {
    setMoreThan10Ratings(checked);
    onFilterChange?.({ valoraciones_usuariosmax: checked });
  };

  const handleSelectSoftSkillsFilter = (option: any) => {
    const existSoftSkill = selectedSoftSkillsFilter.find((sfs: any) => sfs.key == option.key)
    if (!existSoftSkill) {
      let currentSoftSkills = [...selectedSoftSkillsFilter]
      currentSoftSkills.push({ soft_skill: option.value, nivel: 0, key: option.key })
      setSelectedSoftSkillsFilter(currentSoftSkills)
      onFilterChange?.({ softSkillsFiltro: currentSoftSkills })
    }
  }

  const handleDownSoftSkills = (id: string) => {

    const existSoftSkill = selectedSoftSkillsFilter.find((sfs: any) => sfs.key == id)
    if (existSoftSkill) {
      const updatedSoftSkills = selectedSoftSkillsFilter.map((sfs: any) => {
        if (sfs.key === id) {
          return {
            ...sfs,
            nivel: (sfs.nivel || 0) - 1
          };
        }
        return sfs;
      });
      setSelectedSoftSkillsFilter(updatedSoftSkills)
      onFilterChange?.({ softSkillsFiltro: updatedSoftSkills })

    }
  }

  const handleUpSoftSkills = (id:string) => {
    const existSoftSkill = selectedSoftSkillsFilter.find((sfs: any) => sfs.key == id)
    if (existSoftSkill) {
      const updatedSoftSkills = selectedSoftSkillsFilter.map((sfs: any) => {
        if (sfs.key === id) {
          return {
            ...sfs,
            nivel: (sfs.nivel || 0) + 1
          };
        }
        return sfs;
      });
      setSelectedSoftSkillsFilter(updatedSoftSkills)
      onFilterChange?.({ softSkillsFiltro: updatedSoftSkills })
    }
  }

  const handleRemoveSoftSkillsFilter = (id:string) => {
    let currentSoftSkills = [...selectedSoftSkillsFilter]
    currentSoftSkills = currentSoftSkills.filter(css => css.key != id)
    setSelectedSoftSkillsFilter(currentSoftSkills)
    onFilterChange?.({ softSkillsFiltro: currentSoftSkills })
  }

  const handleKeywordsChange = (new2: string[]) => {
    setSelectedEducations(new2);
    onFilterChange?.({ experienciaLaboral: new2 });
  };

  const handleEducationsChange = (newEducations: string[]) => {
    setSelectedEducations(newEducations);
    onFilterChange?.({ educations: newEducations });
  };


  const handleCompaniesChange = (newCompanies: string[]) => {
    onFilterChange?.({ companies: newCompanies });
  };

  const handleExcludedCompaniesChange = (newExcludedCompanies: string[]) => {
    onFilterChange?.({ excluirEmpresa: newExcludedCompanies });
  };

  const toggleSection = (section: any) => {
    setOpenSections((prevState: any) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const addExcludeKeyword = () => {
    if (newExcludeKeyword.length > 0) {
      const updatedKeywords = [...excludeKeywords, ...newExcludeKeyword];
      setExcludeKeywords(updatedKeywords);
      setNewExcludeKeyword([]);
      onFilterChange?.({ excluirEducationsUsers: updatedKeywords });
      onFilterChange?.({ excluirExperienciaLaboral: updatedKeywords });
    }
  };

  const removeExcludeKeyword = (keywordToRemove: string) => {
    const updatedKeywords = excludeKeywords.filter((keyword) => keyword !== keywordToRemove);
    setExcludeKeywords(updatedKeywords);
    onFilterChange?.({ excluirEducationsUsers: updatedKeywords });
    onFilterChange?.({ excluirExperienciaLaboral: updatedKeywords });
  };

  const handleSelect = (value: string) => {

    setSelectedCurrentCountrie(value)

    let updatedExcludedCountries = [...excludedCountries]

    const findCity = filteredCountries.find((cou: any) => cou.pais == value)
    if (findCity) {
      const cities = findCity.ciudades.map((cit: any, index: number) => {
        return {
          id: index,
          ciudad: cit.ciudad
        }
      })
      setFilteredCities(cities)
    }
    if (!selectedCountries.includes(value)) {
      const newSelectedCountries = [...selectedCountries, value];
      setSelectedCountries(newSelectedCountries);
      onFilterChange?.({ paises: newSelectedCountries.filter((cou: string) => !updatedExcludedCountries.includes(cou)) })
    }
  };

  const handleSelectCity = (value: string) => {

    const findCountry = filteredCountries.find((cou: any) => cou.pais == selectedCurrentCountrie)

    let updatedExcludedCountries = [...excludedCountries]
    if (selectedCurrentCountrie) {
      if (!updatedExcludedCountries.includes(selectedCurrentCountrie)) {
        updatedExcludedCountries.push(findCountry.pais)
      }
      setExcludedCountries(updatedExcludedCountries)
    }

    if (!selectedCitys.includes(value)) {
      const newSelectedCitys = [...selectedCitys, value]
      setSelectedCitys(newSelectedCitys)
      onFilterChange?.({ ciudades: newSelectedCitys, paises: selectedCountries.filter((cou: string) => !updatedExcludedCountries.includes(cou)) });
    }
  };
  
  const handleRemoveCity = (city: string) => {
    const newSelectedCitys = selectedCitys.filter(item => item !== city);
    setSelectedCitys(newSelectedCitys);
    let updatedExcludedCountries = [...excludedCountries]
    selectedCountries.map((cou) => {
      const country = filteredCountries.find((fco: any) => fco.pais == cou)
      const hasCities = country.ciudades.some((cit: any) => newSelectedCitys.includes(cit.ciudad))
      if (!hasCities && excludedCountries.includes(country.pais)) {
        updatedExcludedCountries = updatedExcludedCountries.filter((upd: string) => upd != country.pais)
        setExcludedCountries(updatedExcludedCountries)
      }
    })

    onFilterChange?.({ ciudades: newSelectedCitys, paises: selectedCountries.filter((cou: string) => !updatedExcludedCountries.includes(cou)) })
  }

  const handleRemove = (country: string) => {

    const currentExcludedCountries = [...excludedCountries]
    let newSelectedCountries = selectedCountries.filter(item => item !== country);
    newSelectedCountries = newSelectedCountries.filter((cou: string) => !currentExcludedCountries.includes(cou))
    
    setSelectedCountries(newSelectedCountries);
    onFilterChange?.({ paises: newSelectedCountries });
  };

  const handleSectorsChange = (selectedSectors: string[]) => {
    onFilterChange?.({ sectors: selectedSectors });
  };

  const handleAvailability = (value: string, checked: boolean) => {
    let updatedAvailabilityArray = [...selectedAvailability];
    if (checked) {
      updatedAvailabilityArray.push(value)
    } else {
      updatedAvailabilityArray = updatedAvailabilityArray.filter((ava) => ava !== value);
    }
    setSelectedAvailability(updatedAvailabilityArray)
    onFilterChange?.({ jornada: updatedAvailabilityArray })
  }

  const handleSearchCity = (value: string) => {
    setSearchTermCitys(value);
  };

  const filteredCountries = rex_paises.filter((country: any) =>
    country.pais.toLowerCase().includes(searchTermCountries.toLowerCase())
  );

  const filteredSoftSkills = rex_aptitudes.filter((softSkill: any) =>
    softSkill.soft_skill.toLowerCase().includes(searchTermSoftSkills.toLowerCase())
  );

  return (
    <div className="flex flex-col p-4 shadow-md rounded-lg">
      <Sider
        width={234}
        className={`bg-[#FCFCFC] ${inDrawer ? 'p-[5px] border-none ml-[-15px] mt-[-15px]' : "rounded-lg shadow-md p-5 border border-sky-blue0 shadow-gray-400"}`}
      >
        <h2 className="text-heading-md font-bold text-green42 mb-1">Filtros</h2>
        <h2 className="text-body-md font-bold text-green42">
          Refina tu búsqueda
        </h2>
        <h2 
          className="text-caption font-bold text-blue3 mb-6 cursor-pointer"
          onClick={()=> {
            resetFilters()
            resetFiltersSelected()
          }}
        >
          Eliminar todos los filtros
        </h2>

        {/* Búsqueda de trabajo activa */}
        <div className="flex justify-between items-center mb-2">
          <div className=" w-[138px]">
            <h2 className="mt-2 mb-3 text-caption font-semibold text-gray-800 font-sans leading-[16px]">
              Búsqueda de trabajo activa
            </h2>
          </div>
          <div className="mt-[-5px]">
            <ToggleSwitch
              checked={activeSearch}
              defaultChecked={false}
              onChange={() => setActiveSearch(!activeSearch)}
              size="small"
              customClass="switch-small-1"
            />
          </div>
        </div>

        {/* Valoraciones */}
        <div className="mb-[26px]">
          <div
            className="flex justify-between items-center cursor-pointer pb-2"
            onClick={() => toggleSection("valoraciones")}
          >
            <h3 className="font-bold text-heading-sm text-green42">
              Valoraciones
            </h3>
            {openSections.valoraciones ? (
              <img src={IconDrop2} />
            ) : (
              <img src={IconDrop} />
            )}
          </div>
          {openSections.valoraciones && (
            <div className="flex flex-col mb-[26px]">
              <StyledCheckbox
                checked={selectedRatings.includes(0)}
                onChange={() => handleRatingSelect(0)}
              >
                <div className="flex items-center">
                  <RatingBlue
                    filledStars={0}
                    totalStars={1}
                    showRatingValue={true}
                    emptyStarSize={{ width: '20px', height: '20px', marginLeft: '-3px' }}
                  />
                  <span className="ml-2 text-sm text-gray font-sans font-medium">Estrellas</span>
                </div>
              </StyledCheckbox>
              {ratings.slice(1).map((value, index) => (
                <StyledCheckbox
                  key={index + 1}
                  value={value}
                  onChange={() => handleRatingSelect(value)}
                  checked={selectedRatings.includes(value)}
                >
                  <div className="flex items-center">
                    <RatingBlue
                      filledStars={value}
                      totalStars={value}
                      showRatingValue={false}
                      filledStarSize={{ width: '15px', height: '15px', marginTop: '2px', marginRight: '4px' }}
                    />
                  </div>
                </StyledCheckbox>
              ))}

              <Divider className="bg-black my-[8px]"></Divider>

              <StyledCheckbox
                className="mt-0"
                value={moreThan10Ratings}
                onChange={(e) => handleMoreThan10RatingsChange(e.target.checked)}
                checked={moreThan10Ratings}
              >
                <h3 className="font-medium text-[14px] text-green42 font-sans">
                  Solo candidatos con más de 10 valoraciones
                </h3>
              </StyledCheckbox>

            </div>
          )}
        </div>

        {/* Palabras clave */}
        <div className="mb-[26px]">
          <h3 className="font-bold text-heading-sm mb-2 text-green42">
            Palabras clave
          </h3>
          {openSections.palabrasClave && (
            <Keywords
              onKeywordsChange={handleKeywordsChange}
              reset={resetKeywords}
            />
          )}

        </div>

        {/* Excluir parametros */}
        <div className="mb-[26px]">
          <h3 className="font-bold text-heading-sm mb-2 text-green42">
            Excluir parámetros
          </h3>
          <div className="flex justify-between">
            <div className="w-full md:w-[122px]">
              <Input
                placeholder="Escribe aquí..."
                value={newExcludeKeyword.join(", ")}
                onChange={(e) => setNewExcludeKeyword(e.target.value.split(",").map((item) => item.trim()))}
                className="h-[36px] border-[#E1E1E2] font-normal placeholder:font-semibold text-caption flex-1 font-sans text-sm rounded-[4px]"
              />
            </div>
            <div>
              <ButtonCom
                className="w-[64px] font-sans"
                buttons={[
                  {
                    type: "link",
                    label: "Excluir",
                    border: "1px solid #006497",
                    size: "small",
                    textColor: "#006497",
                    fontSize: "12px",
                    fontWeight: "700",
                    textAlign: "center",
                    onClick: addExcludeKeyword,
                  },
                ]}
              />
            </div>
          </div>

          <div className="flex flex-col">
            {excludeKeywords.map((keyword: string, index: number) => (
              <div
                key={index}
                className="flex justify-between items-center mb-[8px]"
              >
                <span className="font-medium text-body-sm text-[#FF8A70]">
                  {keyword}
                </span>
                <span
                  className="ml-2 cursor-pointer transition-colors font-medium text-body-sm text-green22"
                  onClick={() => removeExcludeKeyword(keyword)}
                >
                  X
                </span>
              </div>
            ))}
          </div>
        </div>

        {/* Cualificativos */}
        <div className="mb-[26px]">
          <div
            className="flex justify-between items-center cursor-pointer pb-2"
            onClick={() => toggleSection("cualificativos")}
          >
            <h3 className="font-bold text-heading-sm text-green42">
              Cualificativos
            </h3>
            {openSections.cualificativos ? (
              <img src={IconLine} />
            ) : (
              <img src={IconLine2} />
            )}
          </div>
          {openSections.cualificativos && (
            <div>
              <Select
                placeholder="Selecciona soft skills"
                className="w-full"
                value={null}
                onChange={(value, option) => {
                  handleSelectSoftSkillsFilter(option)
                }}
                showSearch
                filterOption={false}
                dropdownRender={(menu) => (
                  <>
                    <div style={{ maxHeight: 300, overflowY: 'auto' }}> {/* Agrega scroll al desplegable */}
                      {menu}
                    </div>
                  </>
                )}
              >
                {filteredSoftSkills.map((skill:any, index:number) => (
                  <Option key={skill.id} value={skill.soft_skill}>
                    {skill.soft_skill}
                  </Option>
                ))}
              </Select>
              <div className="flex flex-col mt-[8px]">
                {selectedSoftSkillsFilter.map((skill:any, index:number) => (
                  <div
                    key={index}
                    className="w-full flex justify-between items-center mb-2 gap-[6px]"
                  >
                    <div className="w-1/2 flex justify-between">
                      <span className="font-medium text-body-sm text-green22 truncate font-sans">
                        {skill.soft_skill}
                      </span>
                      <span
                        className="ml-2 cursor-pointer transition-colors font-medium text-body-sm text-green22"
                        onClick={() => handleRemoveSoftSkillsFilter(skill.key)} // Remove skill on click
                      >
                        X
                      </span>
                    </div>
                    <div className="flex border justify-between items-center border-blue3 rounded-[4px] h-[36px] w-1/2 px-3">
                      <img 
                        onClick={()=>{
                          if(skill.nivel > 0){
                            handleDownSoftSkills(skill.key)
                          }
                        }}
                        className={`${skill.nivel == 0 ? 'cursor-not-allowed' : 'cursor-pointer'} h-[15px]`} src={IconArrow} 
                      />
                      <span className="font-sans text-base text-[#1A1A1AB2]">{skill.nivel}</span>
                      <img 
                        onClick={()=> {
                          if(skill.nivel < 10){
                            handleUpSoftSkills(skill.key)
                          }
                        }}
                        className={`${skill.nivel == 10? 'cursor-not-allowed': 'cursor-pointer'} rotate-180 h-[15px]`} src={IconArrow} 
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Ubicación */}
        <div className="mb-[26px]">
          <div
            className="flex justify-between items-center cursor-pointer pb-[12px]"
            onClick={() => toggleSection("ubicacion")}
          >
            <h3 className="font-bold text-heading-sm text-green42">
              Ubicación
            </h3>
            {openSections.ubicacion ? (
              <img src={IconLine} />
            ) : (
              <img src={IconLine2} />
            )}
          </div>
          {openSections.ubicacion && (
            <div>
              <div>
                <div>
                  {/* Provincias */}
                  <div className="">
                    <h4 className="font-medium text-body-md mb-[12px] text-gray">Pais</h4>
                    <Select
                      style={{ width: '100%' }}
                      placeholder="Selecciona un país"
                      onSelect={handleSelect}
                      value={selectedCountries}
                      onChange={(value) => handleSelect(value as string)}  // Use el valor como string
                      showSearch
                      dropdownRender={(menu) => (
                        <>
                          <div style={{ maxHeight: 300, overflowY: 'auto' }}> {/* Agrega scroll al desplegable */}
                            {menu}
                          </div>
                        </>
                      )}
                    >
                      {filteredCountries.map((country: any) => (
                        <Option key={country.id} value={country.pais}>
                          {country.pais}
                        </Option>
                      ))}
                    </Select>
                    <div className="flex flex-col my-[12px]">
                      {selectedCountries.map((country, index) => (
                        <div key={index} className="flex items-center mb-2">
                          <span className="font-medium text-caption text-green22">
                            {country}
                          </span>
                          <span
                            className="ml-1 cursor-pointer transition-colors font-medium text-caption text-[#00476D]"
                            onClick={() => handleRemove(country)}
                          >
                            X
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {/* Poblaciones */}
              <div className="mb-[8px]">
                <h4
                  className="font-medium text-body-md mb-[12px] text-gray"
                >Ciudad</h4>
                <Select
                  placeholder="Seleccionar ciudad"
                  className="w-full"
                  onSelect={handleSelectCity}
                  onChange={(value) => handleSelectCity(value as string)}
                  showSearch
                  value={selectedCitys}
                  onSearch={handleSearchCity}
                  dropdownRender={(menu) => (
                    <>
                      <div style={{ maxHeight: 300, overflowY: 'auto' }}>
                        {menu}
                      </div>
                    </>
                  )}
                >
                  {filteredCities?.map((city: any) => (
                    <Option key={city.id} value={city.ciudad}>
                      {city.ciudad}
                    </Option>
                  ))}
                </Select>
                <div className="flex flex-col mt-[12px]">
                  {selectedCitys.map((city, index) => (
                    <div key={index} className="flex items-center mb-2">
                      <span className="font-medium text-caption text-green22">
                        {city}
                      </span>
                      <span
                        className="ml-1 cursor-pointer transition-colors font-medium text-caption text-[#00476D]"
                        onClick={() => handleRemoveCity(city)}
                      >
                        X
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex items-center">
                <StyledCheckbox
                  checked={modalidad}
                  onChange={(e) => {
                    setModalidad(!modalidad)
                    onFilterChange?.({ modalidad: e.target.checked ? 'Remoto' : null });
                  }}
                >
                  <h1 className='text-gray font-medium text-body-sm'>Solo remoto</h1>
                </StyledCheckbox>
              </div>
            </div>
          )}
        </div>

        {/* Empresas */}
        <div className="mb-[26px]">
          <h3 className="font-bold text-heading-sm mb-[7px] text-green42">
            Empresas
          </h3>
          <div className="mb-[7px]">
            <span className="font-normal text-[13px] w-[186px] text-[#5E5E5E] ">
              Añade empresas interesantes para ti
            </span>
            <span className="font-normal text-[13px] w-[186px] text-[#FDBCB4] ">
              {" "}
              o excluye las que no te interesen.
            </span>
          </div>
          <Company
            ref={companyCRef}
            onCompaniesChange={handleCompaniesChange}
            onExcludedCompaniesChange={handleExcludedCompaniesChange}
          />
        </div>

        {/* Experiencia (años) */}
        <div className="mb-[32px]">
          <h3 className="font-bold text-heading-sm mb-2 text-green42">
            Experiencia (años)
          </h3>
          <Slider
            reset={resetExperience}
            onValueChange={handleValueChange}
          />
        </div>


        {/* Educación */}
        <div className="mb-[26px] ">
          <Education
            onEducationsChange={handleEducationsChange}
            sectionTitle="Educación"
            reset={resetEducation}
          />
        </div>

        {/* Disponibilidad */}
        <div className="mb-4">
          <div
            className="flex justify-between items-center cursor-pointer pb-2"
            onClick={() => toggleSection("disponibilidad")}
          >
            <h3 className="font-bold text-heading-sm text-green42">
              Disponibilidad
            </h3>
            {openSections.disponibilidad ? (
              <img src={IconDrop2} />
            ) : (
              <img src={IconDrop} />
            )}
          </div>

          {openSections.disponibilidad && (
            <div className="mt-2">
              {availability.map((option: any, index) => (
                <StyledCheckbox
                  checked={selectedAvailability.includes(option.value)}
                  onChange={(e) => {
                    handleAvailability(option.value, e.target.checked)
                  }}
                  className="mb-[8px]" key={index} value={option.value}
                >
                  <span className="font-bold !text-base text-green42">{option.label}</span>
                </StyledCheckbox>
              ))}
            </div>
          )}
        </div>

        {/* Sector */}
        <div className="mb-4">
          <Sector sectionTitle="Experiencia en el sector"
            onSectorsChange={handleSectorsChange}
            reset={resetSectors}
          />
        </div>

        <div className="mb-4">
          <div
            className="flex justify-between items-center cursor-not-allowed"
            onClick={() => {
              // toggleSection("busquedasSugeridas")
            }}
          >
            <h3 className="font-bold text-heading-sm mb-2 text-green42">
              Búsquedas sugeridas
            </h3>
            {openSections.busquedasSugeridas ? (
              <img src={IconDrop2} />
            ) : (
              <img src={IconDrop} />
            )}
          </div>
          {openSections.busquedasSugeridas && (
            <div className="flex flex-col text-bluec">
              {[
                "Dedicadas a la logística",
                "Mejor valoradas de Ciudad Real",
                "Reclutadores independientes que operan en Ciudad Real",
                "Especializadas en retail",
              ].map((sector) => (
                <div key={sector} className="mb-2 font-bold text-body-md">
                  {sector}
                </div>
              ))}
            </div>
          )}
        </div>
      </Sider>
    </div>
  );
};

export default Sidebar;
