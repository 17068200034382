import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../store/store';
import {
  FETCH_CV_GENERAL_REQUEST,
  FETCH_CV_GENERAL_SUCCESS,
  FETCH_CV_GENERAL_FAILURE,
  SET_FILTER_PARAMS,
  CvGeneralActionTypes,
  CvGeneral,
  UpdateUsersGraphSoftSkillsAction,
  UPDATE_USER_GRAPH_SOFT_SKILLS,
  DetailSoftSkillsFormat,
  DataSoftSkill,
  UpdateCvGeneralSuccessAction,
  UPDATE_CV_GENERAL
} from '../../../../constants/pages/searchCV/GetSearchCV';
import FetchWithIP from '../../utils/FetchHeaders';

export const fetchCvGeneralRequest = (): CvGeneralActionTypes => ({
  type: FETCH_CV_GENERAL_REQUEST,
});

interface SoftSkillFiltro {
  nivel : number,
  soft_skill : string,
  key : string
}

export const addOrRemoveUserGraphSoftSkills = (
  data:{ id:number, personas:{nombre:string, apellido_paterno:string, apellido_materno?:string}, soft_skills_usuarios:[], checked : boolean},
  addUser:boolean,
  seeGraph: boolean
): ThunkAction<Promise<any>, RootState, unknown, any> => async (dispatch, getState) => {

  try {

    let { rex_users_graph_sk, labels_graph_sk, cvGeneral } = getState().getSearchCV;

    if(seeGraph){

      if(!data.checked){

        const count_users:number = rex_users_graph_sk.users.length
        const idxKeyUser:number = labels_graph_sk[count_users]['id']
  
        if(data.soft_skills_usuarios.length > 0){
  
          const dataFormated = data.soft_skills_usuarios.flatMap((item: DetailSoftSkillsFormat)=> [
            {
              subject: item.soft_skills.soft_skill,
              value: item.nivel,
            },
            {
              subject: item.soft_skills.soft_skill_c,
              value: 10 - item.nivel,
            }
          ])
          
          dataFormated.forEach((df: {value:number, subject:string}) => {
            const idxSubject:number = rex_users_graph_sk.data.findIndex(ds => ds.subject == df.subject)
            if(idxSubject != -1){            
              rex_users_graph_sk.data[idxSubject][data.id] = df.value
            }else{
              rex_users_graph_sk.data.push({
                subject: df.subject,
                idxSubject : idxKeyUser,
                fullMark : 10,
                [data.id] : df.value
              })
            }
          })
  
          rex_users_graph_sk.users.push({
            nombre : `${data.personas.nombre} ${data.personas.apellido_paterno} ${data.personas.apellido_materno}`,
            id: data.id,
            nameKey: data.id.toString()
          })
  
        }else{
  
          if(count_users == 0 && rex_users_graph_sk.data.length == 0){
            console.log("Agrega aqui")
            rex_users_graph_sk.data.push(
              { subject: 'Adaptable', idxSubject : 0, fullMark : 10, [data.id] : 0 },
              { subject: 'Consistente', idxSubject : 0, fullMark : 10, [data.id] : 0 },
              { subject: 'Detallista', idxSubject : 0, fullMark : 10, [data.id] : 0 },
              { subject: 'Innovador', idxSubject : 0, fullMark : 10, [data.id] : 0 },
              { subject: 'Metódico', idxSubject : 0, fullMark : 10, [data.id] : 0 },
              { subject: 'Proactivo', idxSubject : 0, fullMark : 10, [data.id] : 0 },
              { subject: 'Resiliente', idxSubject : 0, fullMark : 10, [data.id] : 0 },
              { subject: 'Visionario', idxSubject : 0, fullMark : 10, [data.id] : 0 },
            )
          }else{
            rex_users_graph_sk.data.map(dat => {
              dat[data.id] = 0
            })
          }
  
          rex_users_graph_sk.users.push({
            nombre : `${data.personas.nombre} ${data.personas.apellido_paterno} ${data.personas.apellido_materno}`,
            id: data.id,
            nameKey: data.id.toString()
          })
        }
      }
    }else{

      if(addUser){
  
        const count_users:number = rex_users_graph_sk.users.length
        const idxKeyUser:number = labels_graph_sk[count_users]['id']
  
        if(data.soft_skills_usuarios.length > 0){
  
          const dataFormated = data.soft_skills_usuarios.flatMap((item: DetailSoftSkillsFormat)=> [
            {
              subject: item.soft_skills.soft_skill,
              value: item.nivel,
            },
            {
              subject: item.soft_skills.soft_skill_c,
              value: 10 - item.nivel,
            }
          ])
          
          dataFormated.forEach((df: {value:number, subject:string}) => {
            const idxSubject:number = rex_users_graph_sk.data.findIndex(ds => ds.subject == df.subject)
            if(idxSubject != -1){            
              rex_users_graph_sk.data[idxSubject][data.id] = df.value
            }else{
              console.log("su data.id: " + data.id )
              rex_users_graph_sk.data.push({
                subject: df.subject,
                idxSubject : idxKeyUser,
                fullMark : 10,
                [data.id] : df.value
              })
            }
          })
  
          rex_users_graph_sk.users.push({
            nombre : `${data.personas.nombre} ${data.personas.apellido_paterno} ${data.personas.apellido_materno}`,
            id: data.id,
            nameKey: data.id.toString()
          })
  
        }else{
  
          if(count_users == 0){
            rex_users_graph_sk.data.push(
              { subject: 'Adaptable', idxSubject : 0, fullMark : 10, [data.id] : 0 },
              { subject: 'Consistente', idxSubject : 0, fullMark : 10, [data.id] : 0 },
              { subject: 'Detallista', idxSubject : 0, fullMark : 10, [data.id] : 0 },
              { subject: 'Innovador', idxSubject : 0, fullMark : 10, [data.id] : 0 },
              { subject: 'Metódico', idxSubject : 0, fullMark : 10, [data.id] : 0 },
              { subject: 'Proactivo', idxSubject : 0, fullMark : 10, [data.id] : 0 },
              { subject: 'Resiliente', idxSubject : 0, fullMark : 10, [data.id] : 0 },
              { subject: 'Visionario', idxSubject : 0, fullMark : 10, [data.id] : 0 },
            )
          }else{
            rex_users_graph_sk.data.map(dat => {
              dat[data.id] = 0
            })
          }
  
          rex_users_graph_sk.users.push({
            nombre : `${data.personas.nombre} ${data.personas.apellido_paterno} ${data.personas.apellido_materno}`,
            id: data.id,
            nameKey: data.id.toString()
          })
        }
      }else{
  
        rex_users_graph_sk.users = rex_users_graph_sk.users.filter(user => user.id != data.id)
        rex_users_graph_sk.data.map(dat => {
          delete dat[data.id]
          return dat
        })
      }
    }
    
    const idxUser: number = cvGeneral.findIndex(cv => cv.id == data.id)
    cvGeneral[idxUser]['checked'] = addUser
    dispatch(updateCvGeneral(cvGeneral))

  } catch (error) {
    return { payload: [] };
  }
};

export const updateUsersGraphSoftSkills = (data: DataSoftSkill[], users: []): UpdateUsersGraphSoftSkillsAction => ({
  type : UPDATE_USER_GRAPH_SOFT_SKILLS,
  payload : { data, users }
})

export const updateCvGeneral = (data:any): UpdateCvGeneralSuccessAction => ({
  type : UPDATE_CV_GENERAL,
  payload : data
})

export const fetchCvGeneralSuccess = (data: CvGeneral[],  meta: { total: number; limit: number; page: number }, resetUserGraph:boolean): CvGeneralActionTypes => ({
  type: FETCH_CV_GENERAL_SUCCESS,
  payload: { data, meta, resetUserGraph },
});

export const fetchCvGeneralFailure = (error: string): CvGeneralActionTypes => ({
  type: FETCH_CV_GENERAL_FAILURE,
  payload: error,
});

export const setFilterParams = (filters: any): CvGeneralActionTypes => ({
  type: SET_FILTER_PARAMS,
  payload: filters,
});

export const getCvGeneralReducer = (
  resetUserGraph:boolean,
  page?: number, 
  limit?: number,
  sortColumn?: string,
  centrosEducativosArray?: string[],
  cargo?: string,
  pais?:string,
  ciudad?:string,
  meses_experienciaArray?:number[],
  valoraciones_usuariosArray?:number[],
  valoraciones_usuariosmax?:boolean,
  sectoresArray?: string[],
  paisesArray?: string[],
  ciudadesArray?: string[],
  soft_skillsArray?: string[],
  experiencia_laboralArray?: string[],
  excluir_experiencia_laboralArray?: string[],
  empresasArray?: string[],
  sortOrderColumn?: string | null,
  activeSearch?: boolean,
  modalidad?: string | null,
  jornadaArray? : string[],
  excluir_EmpresaArray? : string[],
  soft_skills_filtroArray? : SoftSkillFiltro[]
): ThunkAction<Promise<any>, RootState, unknown, CvGeneralActionTypes> => async (dispatch) => {
  dispatch(fetchCvGeneralRequest());
  
  if (pais) {
    console.log('hola-------------------------------------------------')
    dispatch(setFilterParams(pais));
  }
  if (ciudad) {
    console.log('hola22222222222222222222222-------------------------------------------------')
    dispatch(setFilterParams(ciudad));
  }
  if (centrosEducativosArray) {
    console.log('hola22222222222222222222222-------------------------------------------------')
    dispatch(setFilterParams(centrosEducativosArray));
  }
  if (cargo) {
    console.log('hola22222222222222222222222-------------------------------------------------')
    dispatch(setFilterParams(cargo));
  }
  if (meses_experienciaArray) {
    console.log('hola22222222222222222222222-------------------------------------------------')
    dispatch(setFilterParams(meses_experienciaArray));
  }
  if (valoraciones_usuariosArray) {
    console.log('hola22222222222222222222222-------------------------------------------------')
    dispatch(setFilterParams(valoraciones_usuariosArray));
  }
  if (valoraciones_usuariosmax) {
    console.log('hola22222222222222222222222-------------------------------------------------')
    dispatch(setFilterParams(valoraciones_usuariosmax));
  }
  if (sectoresArray) {
    console.log('hola22222222222222222222222-------------------------------------------------')
    dispatch(setFilterParams(sectoresArray));
  }
  if (paisesArray) {
    console.log('hola22222222222222222222222-------------------------------------------------')
    dispatch(setFilterParams(paisesArray));
  }
  if (ciudadesArray) {
    console.log('hola22222222222222222222222-------------------------------------------------')
    dispatch(setFilterParams(ciudadesArray));
  }
  if (soft_skillsArray) {
    console.log('hola22222222222222222222222-------------------------------------------------')
    dispatch(setFilterParams(soft_skillsArray));
  }
  if (experiencia_laboralArray) {
    console.log('hola22222222222222222222222-------------------------------------------------')
    dispatch(setFilterParams(experiencia_laboralArray));
  }
  if (excluir_experiencia_laboralArray) {
    console.log('hola22222222222222222222222-------------------------------------------------')
    dispatch(setFilterParams(excluir_experiencia_laboralArray));
  }
  if (empresasArray) {
    console.log('hola22222222222222222222222-------------------------------------------------')
    dispatch(setFilterParams(empresasArray));
  }

  if (jornadaArray) {
    dispatch(setFilterParams(jornadaArray));
  }
  if (activeSearch) {
    dispatch(setFilterParams(activeSearch));
  }
  if (modalidad) {
    dispatch(setFilterParams(modalidad));
  }
  if (jornadaArray) {
    dispatch(setFilterParams(jornadaArray));
  }
  if (excluir_EmpresaArray) {
    dispatch(setFilterParams(excluir_EmpresaArray));
  }
  if (soft_skills_filtroArray) {
    dispatch(setFilterParams(soft_skills_filtroArray));
  }


  const queryParams = new URLSearchParams({
    sortOrder: sortOrderColumn && (sortColumn == 'valoracionesPromedio' || sortColumn == 'meses_experiencia') ? sortOrderColumn :'desc',
    ...(page !== undefined && { page: page.toString() }),
    ...(limit !== undefined && { limit: limit.toString() }),
    ...(sortColumn && { sortColumn }),
    ...(cargo && { cargo }),
    ...(pais && { pais }),  
    ...(ciudad && { ciudad }),
  }).toString();

  try {
    const response = await FetchWithIP(`cv-general?${queryParams}`, {
      method: 'POST',
    },{
      centrosEducativosArray,
      meses_experienciaArray,
      valoraciones_usuariosArray,
      valoraciones_usuariosmax,
      sectoresArray,
      paisesArray,
      ciudadesArray,
      soft_skillsArray,
      experiencia_laboralArray,
      excluir_experiencia_laboralArray,
      empresasArray,
      activeSearch,
      modalidad,
      jornadaArray,
      excluir_EmpresaArray,
      soft_skills_filtroArray
    });

    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();

    if (responseData && responseData.data) {
      const { total, limit, page } = responseData.meta;
      dispatch(fetchCvGeneralSuccess(
        responseData.data, { total, limit, page}, resetUserGraph));
    } else {
      throw new Error('Datos vacíos en la respuesta');
    }

    return

  } catch (error: any) {
    dispatch(fetchCvGeneralFailure(error.message || 'Error al cargar CV general'));
    return { error: error.message };
  }
};