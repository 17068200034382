import React from 'react';
import { Avatar, Card, Button } from 'antd';
import IconUsers from '../../assets/icons/IconUsers.svg';
import IconB from '../../assets/icons/IconB.svg';
import IconCheck from '../../assets/icons/IconCheck.svg';
import IconTag from '../../assets/icons/tag.svg';
import IconPersons from '../../assets/icons/persons.svg';
import IconAlerts from '../../assets/icons/alerts.svg';
import IconAlertsWhitout from '../../assets/icons/alertWhitout.svg';
import CardStats from './CardStats';

const { Meta } = Card;

interface CardEmpresaProps {
  title: string;
  description: string;
  location: string;
  link: string;
  avatarUrl: string | undefined;
  reviews: number;
  activeOffers: number;
  followers: number;
  workers: number;
  categories: string[];
  cardType?: number;
  onClick: () => void;
  className?: string;
  sector: string;
  follow: boolean;
}

const CardEmpresa: React.FC<CardEmpresaProps> = ({
  title,
  description,
  location,
  link,
  avatarUrl,
  reviews,
  activeOffers,
  followers,
  workers,
  onClick,
  className,
  sector,
  follow = false
}) => {

  const handleClick = () => {
    onClick();
  };

  return (
    <Card
      onClick={handleClick}
      className={`p-5 bg-[#FBFDFE] overflow-hidden relative cursor-pointer ${className} shadow-md hover:shadow-lg hover:border-[#81BFEC]`}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '0px',
        borderRadius: '8px',
        height: 'auto',
      }}
    >
      <Meta
        style={{
          width: '100%'
        }}
        avatar={<Avatar shape="square" className="w-24 h-24 mb-2 md:mb-0" src={avatarUrl} />}
        title={
          <div className="flex items-center justify-between w-full -mb-1">
            <span className="text-base font-sans font-bold text-[#333333]">{title}</span>
          </div>
        }
        description={
          <div className="text-sm text-black">
            <div className="container pr-5">
              <span title={description} className="inline-block truncate max-w-full text-body-sm font-medium text-[#333333] font-sans single-line">{description}</span>
            </div>
            <span>
              • {location} • Valoraciones • +{workers} trabajadores
            </span>
            <br />
            <div className='mt-1'>
              <a className="text-sm font-bold text-[#666666] font-sans" href={link}>{link}</a>
            </div>
            <div className="flex mt-2 overflow-hidden space-x-1 gap-2 md:gap-1">
              <CardStats
                text={reviews + " valoraciones"}
                iconButton={<img src={IconCheck} className="" />}
              />
              <CardStats
                text={activeOffers + " Ofertas activas"}
                iconButton={<img src={IconB} className="" />}
              />

              <CardStats
                text={followers + " Seguidores"}
                iconButton={<img src={IconUsers} className="" />}
              />

              <CardStats
                text={`${+workers} trabajadores`}
                iconButton={<img src={IconPersons} className="" />}
              />
              <img src={IconTag} className="text-blue4" />

              <CardStats
                textEllipsis={true}
                text={sector}
                customClassName='w-[148px] inline-block truncate  whitespace-nowrap'
              />
            </div>
          </div>
        }
      />
      <img
        src={
          follow
            ? IconAlerts
            : IconAlertsWhitout
        }
        className="absolute top-4 right-7 text-2xl hidden text-blue3 md:block"
      />
    </Card>
  );
};

export default CardEmpresa;
