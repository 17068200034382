import React from "react";
import { Row, Col, Input, Button, Card } from "antd";
import frameImage from "../../assets/img/homeCompany/Frame.svg";
import group25Image from "../../assets/img/homeCompany/Group 25.svg";
import grupImage from "../../assets/img/homeCompany/Group.svg";
import rectangleImage from "../../assets/img/homeCompany/Rectangle.svg";
import textRockies from "../../assets/img/homeCompany/textRockies/textRockes.png";
import section2v2 from "../../assets/img/homeCompany/section2v2.svg";
import section3 from "../../assets/img/homeCompany/section3.svg";
import section4 from "../../assets/img/homeCompany/section4.svg";
import ats from "../../assets/img/homeCompany/ATS.svg";
import ia from "../../assets/img/homeCompany/IA.svg";
import "../../styles/pages/homeCompany/HomeCompany.css";
import CardTopHome from "../../components/homes/CardTopHome";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/Footer";

const HomeCompany: React.FC = () => {

  const navigate = useNavigate();

  const styleButtonJoinUs = "font-sans bg-sky-blue0 text-black2 text-body-md border-none rounded-[4px] hover:bg-sky-blue0"

  return (
    <>
      <div>
        <CardTopHome />
      </div>
      <div className="flex justify-center">
        <div className="w-full 2x2:w-page-width">
          <div className="container-homeCompany">
            {/* Primera sección */}
            <div className="section section1 ">
              <Row >
                <Col xs={24} md={12} className="padding0">
                  <div className="textColumn0">
                    <h1 className="title0 text-blue0 font-sans xs:text-heading-sm sm:text-heading-sm md:text-heading-sm lg:text-body-xl 2x3:text-heading-lg font-black text-[28px] leading-[34px] -tracking-[0.019em]">
                      Conoce a tus candidatos como nunca antes se había hecho
                    </h1>
                    <p className="description text-blue0 font-sans 2x3:text-body-md xs:text-body-xs sm:text-body-xs md:text-body-xs lg:text-body-sm leading-[21px] -tracking-[0.019em]">
                      Busca por características basadas en los soft skills, verifica
                      los usuarios mediante sus valoraciones y deja que la
                      Inteligencia Artificial haga tu trabajo más fácil.
                    </p>
                  </div>
                  <div className="butonContainers">
                    <Input
                      placeholder="Introducir correo electrónico"
                      className="input text-base font-sans border-solid border-[#5C5C5C] rounded-[4px]"
                    />
                    <Button
                      className="button0 font-sans font-normal border-none"
                      onClick={() => {
                        navigate('/login-enterprise');
                      }}
                    >
                      Continuar con correo electrónico
                    </Button>
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <img src={rectangleImage} alt="Rectangle" className="image0" />
                  <img src={grupImage} alt="Radar" className="image1" />
                  <img src={group25Image} alt="Ratings" className="image2" />
                  <img src={frameImage} alt="Candidate" className="image3" />
                  <img src={ats} alt="ATS" className="image4" />
                  <img src={ia} alt="IA" className="image5" />
                </Col>
              </Row>
            </div>

            {/* Segunda sección */}
            <div className="section0 section2 py:1 md:py-12 h-[100%] px-0">
              <Row className="">
                <Col xs={24} md={12} className="padding3 mb-0 md:mb-0 text-black">
                  <div className="textColumn2 font-sans pr-4">
                    <h1 className="text-2xl font-bold leading-[38px]">
                      Merêre es el símbolo del <span className="underline">Mérito</span>
                    </h1><br />
                    <div className="text-lg leading-[28px] font-medium">
                      <p className="font-sans">
                      En Merêre, creemos que quienes mejor desempeñan su trabajo
                      merecen encontrarlos más rápidamente.
                      </p><br />
                      <p className="font-sans">
                      Optimizamos la contratación alineando la personalidad de los
                      candidatos con las exigencias de sus roles. Esto reduce la
                      rotación laboral y mejora la satisfacción y productividad en el
                      trabajo.
                      </p><br />
                      <p className="font-sans">
                      Nos enfocamos en establecer relaciones laborables duraderas,
                      donde cada trabajador se siente valorado y escencial para el
                      exito de la empresa.
                      </p><br />
                    </div>
                    <Button
                      className={`button2 ${styleButtonJoinUs}`}
                    >
                      Únete a nosotros
                    </Button>
                  </div>
                </Col>
                <Col xs={24} md={12} className="flex justify-center">
                  <div className="w-[95%] md:w-[100%] bg-[#BDD0FB] h-[560px] rounded-3xl flex flex-col justify-end">
                      <div className="w-[100%] h-[85%] flex items-end">
                        <img 
                          src={section2v2} 
                          className=" mx-auto"
                        />
                      </div>
                      <div className="bg-[#8CADF8] h-[15%] rounded-b-3xl">
                      </div>
                  </div>
                </Col>
              </Row>
            </div>

            {/* Tercera sección */}
            <div className="section0 section3 py-0 md:py-12 h-[100%]">
              <Row className="flex flex-col-reverse md:flex-row">
                <Col xs={24} md={12} className="imageColumn3 h-[100%]">
                  <Card className="card2 bg-green2 top-0 rounded-[6px] border-none">
                    <img src={section3} alt="section3" className="" />
                  </Card>
                </Col>
                <Col xs={24} md={12} className="padding2 font-sans text-black">
                  <div className="textColumn3">

                    <h1 className="text-2xl font-bold leading-[38px]">
                      Optimización de tareas mediante ATS
                    </h1><br />
                    <div className="text-lg leading-[28px] font-medium">
                      <p className="font-sans">
                        Merêre acelera la integración de perfiles con las necesidades de roles, facilitando la colocación eficiente de candidatos.
                        Gracias a la compatibilidad con sistemas ATS, las empresas pueden gestionar aplicaciones eficientemente, acelerar los tiempos de contratación y mejorar la experiencia del candidato,asegurando relaciones laborales duraderas y productivas.
                      </p><br />
                    </div>
                    <Button
                      className={`button3 ${styleButtonJoinUs}`}
                    >
                      Únete a nosotros
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>

            {/* Cuarta sección */}
            <div className="section0 section4 pb-0 md:pb-12 pt-5 h-[100%]">
              <Row>
                <Col xs={24} md={12} className="padding3 text-black">

                  <div className="textColumn4 font-sans font-medium">
                    <h1 className="text-2xl font-bold leading-[38px]">
                      A la vanguardia de la tecnología con la Inteligencia Artificial
                      aplicada a los procesos de selección
                    </h1><br />
                    <div className="text-lg leading-[28px]">

                      <p className="font-sans">
                        Descubre cómo la inteligencia artificial revoluciona la forma en
                        que conectamos candidatos y empresas. La IA no solo optimiza la
                        búsqueda y selección de talento al predecir el ajuste perfecto
                        entre candidatos y roles, sino que también simplifica los
                        procesos, haciendo que la contratación sea más rápida y menos
                        tediosa.
                      </p><br />
                      <p className="font-sans">
                        Con nuestra tecnología, la eficiencia se encuentra con la
                        efectividad, haciendo que cada paso del proceso de reclutamiento
                        sea más fácil y más inteligente.
                      </p><br />
                    </div>
                    <Button
                      className={`button4 ${styleButtonJoinUs}`}
                    >
                      Únete a nosotros
                    </Button>
                  </div>
                </Col>
                <Col xs={24} md={12} className="pt-0 md:pt-0">
                  <Card className="card4 bg-pink4 rounded-[6px] border-none">
                    <img src={section4} alt="section4" className="image10" />
                  </Card>
                </Col>
              </Row>
            </div>

            {/* Quinta sección */}
            <div className="section0 section5 py-0 md:py-12 h-[100%]">
              <Row className="flex flex-col-reverse md:flex-row">
                <Col xs={24} md={12} className="pt-10 md:pt-0">
                  <div className="bg-[#C1DCFB] h-[478px] rounded-md px-14 text-white flex justify-center items-center">
                    <img src={textRockies} alt="text-rockies" className="md:w-4/5 pt-[10%]"/>
                  </div>
                </Col>
                <Col xs={24} md={12} className="padding4 px-8 text-black">
                  <div className="textColumn5 font-sans">
                    <h1 className="text-2xl font-bold leading-[38px]">
                      Visibilidad sin coste de Los Rockies
                    </h1><br />
                    <div className="text-lg leading-[28px] font-medium">
                      <p className="font-sans">
                        Nuestra iniciativa para dar visibilidad a talentos sin
                        experiencia previa, como estudiantes o quienes entran al mercado
                        laboral por primera vez.
                      </p><br />
                      <p className="font-sans">
                        En nuestro portal, ofrecemos a los reclutadores acceso gratuito
                        a los CVs de estos candidatos, permitiéndoles descubrir y
                        potenciar nuevas habilidades que son cruciales para el
                        crecimiento y la diversidad de sus equipos.
                      </p>
                    </div>
                    <Button
                      className={`button5 ${styleButtonJoinUs}`}
                    >
                      Únete a nosotros
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <Footer/>
        </div>
      </div>
    </>

  );
};

export default HomeCompany;
