import React, { useState, useEffect } from "react";
import { Row, Col, Card, Divider, Drawer, Button, Menu, Dropdown, message } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store/store";
import { fetchApplicationPhasesReducer } from "../../../redux/actions/common/fase/GetApplicationPhases";
import OffersProfileInfo from "../../../components/pages/offers/offersProfile/OffersProfileInfo";
import TableProfile from "../../../components/pages/offers/offersProfile/TableOffers";
import SidebarOffers from "../../../components/pages/offers/offersProfile/SidebarOffersProfile";
import Stream from "../../../assets/icons/view_stream.svg";
import List from "../../../assets/icons/view_list.svg";
import IconButton from "../../../components/pages/searchCV/IconButton";
import ModalSoftSkills from "../../../components/pages/offers/ModalSoftSkills";
import { GetPostulacionesByOfertaReducer } from "../../../redux/actions/pages/offers/offersProfile/GetOffersProfile";
import PostulationSkeleton from "../../../components/pages/offers/PostulationSkeleton";

interface FilterParams {
  educations?: string[];
  keywords?: string[];
  ratings?: number[];
  sectors?: string[];
  tamanioArray?: { desde: number; hasta: number }[];
  experiences?: number[];
  valoraciones_usuariosmax?: boolean;
  paises?: string[];
  ciudades?: string[];
  softSkills?: string[];
  experienciaLaboral?: string[];
  educationsUsers?: string[];
  excluirEducationsUsers?: string[];
  excluirExperienciaLaboral?: string[];
  companies?: string[];
  experiencia_nula?: number[];
  modalidad_remoto?: string[];
  activeSearch?: boolean;
  jornada?: string[];
  excluirEmpresa?: string[];
  excluirPalabrasClave?: string[]
}

const clearFilters = {
  educations: [],
  keywords: [],
  sectors: [],
  tamanioArray: [],
  experiences: [],
  ratings: [],
  valoraciones_usuariosmax: false,
  paises: [],
  ciudades: [],
  softSkills: [],
  experienciaLaboral: [],
  educationsUsers: [],
  excluirEducationsUsers: [],
  excluirExperienciaLaboral: [],
  companies: [],
  experiencia_nula: [],
  modalidad_remoto: [],
  activeSearch: true,
  jornada: [],
  excluirEmpresa: [],
}

const OffersProfile: React.FC = () => {
  const location = useLocation();
  const { nombreOferta } = location.state;
  const { id } = useParams<{ id: string }>();
  const [selectedView, setSelectedView] = useState<string>("stream");
  const [showSoftSkills, setShowSoftSkills] = useState<boolean>(false)
  const [activeSearch, setActiveSearch] = useState<boolean>(true)
  const offerId = parseInt(id || "0", 10);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [loadingMoreData, setLoadingMoreData] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);

  const { rex_phases, rex_loadingp } = useSelector((state: RootState) => state.getApplicationPhases);

  const {
    rex_limit,
    rex_total,
    postulaciones,
    rex_users_graph_sk
  } = useSelector((state: RootState) => state.getOffersProfile);

  useEffect(() => {
    dispatch(fetchApplicationPhasesReducer());
  }, []);

  const handleViewSelect = (view: string) => {
    setSelectedView(view);
  };

  const handlePhaseChange = (phase: string) => {
    console.log(`Changed phase to: ${phase}`);
    // Here you would typically dispatch an action to update the phase
  };

  const phaseMenu = (
    <Menu onClick={({ key }) => handlePhaseChange(key.toString())}>
      {rex_phases.map((phase) => (
        <Menu.Item key={phase.id}>{phase.fase}</Menu.Item>
      ))}
    </Menu>
  );
  const [page, setPage] = useState(1);

  const [filterParams, setFilterParams] = useState<FilterParams>(clearFilters);

  const handleFilterChange = (newFilters: Partial<FilterParams>) => {
    console.log("newFilters")
    console.log(newFilters)
    setFilterParams(prevParams => ({
      ...prevParams,
      ...newFilters,
    }));
  };

  useEffect(() => {
    setPage(1);
    setHasMore(true);

  }, [filterParams])

  const handleResetFilters = () => {
    setFilterParams(clearFilters)
  }

  useEffect(() => {
    if (rex_total > 0) {
      if (rex_total == 0) setHasMore(false)
      if (postulaciones?.length == rex_total) {
        setHasMore(false)
      }
    }
  }, [rex_total, postulaciones])

  useEffect(() => {
    if (postulaciones.length === 0 || page === 1) {
      console.log("ENTRA ACA")
      setLoading(true);
      const selectedRatings = filterParams.ratings && filterParams.ratings.length > 0 ? filterParams.ratings : undefined
      const selectedExperienciaLaboral = filterParams.experienciaLaboral && filterParams.experienciaLaboral?.length > 0 ? filterParams.experienciaLaboral : undefined
      const selectedSoftSkills = filterParams.softSkills && filterParams.softSkills?.length > 0 ? filterParams.softSkills : undefined
      const selectedPaises = filterParams.paises && filterParams.paises?.length > 0 ? filterParams.paises : undefined
      const selectedCiudades = filterParams.ciudades && filterParams.ciudades?.length > 0 ? filterParams.ciudades : undefined
      const selectedCompanies = filterParams.companies && filterParams.companies?.length > 0 ? filterParams.companies : undefined
      const selectedExperiences = filterParams.experiences && filterParams.experiences?.length > 0 ? filterParams.experiences : undefined
      const selectedEducations = filterParams.educations && filterParams.educations?.length > 0 ? filterParams.educations : undefined
      const selectedSectors = filterParams.sectors && filterParams.sectors?.length > 0 ? filterParams.sectors : undefined
      const selectedValoracionesMax = filterParams.valoraciones_usuariosmax ? filterParams.valoraciones_usuariosmax : undefined
      const selectedExperienciaNula = filterParams.experiencia_nula && filterParams.experiencia_nula.length > 0 ? filterParams.experiencia_nula : undefined
      const selectedModalidadRemoto = filterParams.modalidad_remoto && filterParams.modalidad_remoto?.length > 0 ? filterParams.modalidad_remoto : undefined
      const selectedExcluirExperienciaLaboral = filterParams.excluirExperienciaLaboral && filterParams.excluirExperienciaLaboral?.length > 0 ? filterParams.excluirExperienciaLaboral : undefined
      const activeSearch = filterParams.activeSearch
      const selectedJornada = filterParams.jornada && filterParams.jornada?.length > 0 ? filterParams.jornada : undefined
      const selectedExcluirEmpresa = filterParams.excluirEmpresa && filterParams.excluirEmpresa?.length > 0 ? filterParams.excluirEmpresa : undefined

      console.log("activeSearch")
      console.log(activeSearch)
      dispatch(GetPostulacionesByOfertaReducer(
        offerId,
        1,
        10,
        selectedRatings,
        selectedExperienciaLaboral,
        selectedSoftSkills,
        selectedPaises,
        selectedCiudades,
        selectedCompanies,
        selectedExperiences,
        selectedEducations,
        selectedSectors,
        selectedValoracionesMax,
        selectedExperienciaNula,
        selectedModalidadRemoto,
        selectedExcluirExperienciaLaboral,
        activeSearch,
        selectedJornada,
        selectedExcluirEmpresa,
      )).then(() => {
        setLoading(false)
      });
    }
  }, [offerId, page, rex_limit, filterParams]);


  useEffect(() => {
    if (rex_total > 0) {
      if (rex_total == 0) setHasMore(false)
      if (postulaciones?.length == rex_total) {
        setHasMore(false)
      }
    }
  }, [rex_total, postulaciones])

  const fetchMoreData = () => {
    if (loadingMoreData || !hasMore) {
      return;
    }
    setLoadingMoreData(true);
    const nextPage = page + 1;
    const totalPages = Math.ceil(rex_total / rex_limit);
    setTimeout(() => {
      const selectedRatings = filterParams.ratings && filterParams.ratings.length > 0 ? filterParams.ratings : undefined
      const selectedExperienciaLaboral = filterParams.experienciaLaboral && filterParams.experienciaLaboral?.length > 0 ? filterParams.experienciaLaboral : undefined
      const selectedSoftSkills = filterParams.softSkills && filterParams.softSkills?.length > 0 ? filterParams.softSkills : undefined
      const selectedPaises = filterParams.paises && filterParams.paises?.length > 0 ? filterParams.paises : undefined
      const selectedCiudades = filterParams.ciudades && filterParams.ciudades?.length > 0 ? filterParams.ciudades : undefined
      const selectedCompanies = filterParams.companies && filterParams.companies?.length > 0 ? filterParams.companies : undefined
      const selectedExperiences = filterParams.experiences && filterParams.experiences?.length > 0 ? filterParams.experiences : undefined
      const selectedEducations = filterParams.educations && filterParams.educations?.length > 0 ? filterParams.educations : undefined
      const selectedSectors = filterParams.sectors && filterParams.sectors?.length > 0 ? filterParams.sectors : undefined
      const selectedValoracionesMax = filterParams.valoraciones_usuariosmax ? filterParams.valoraciones_usuariosmax : undefined
      const selectedExperienciaNula = filterParams.experiencia_nula && filterParams.experiencia_nula.length > 0 ? filterParams.experiencia_nula : undefined
      const selectedModalidadRemoto = filterParams.modalidad_remoto && filterParams.modalidad_remoto?.length > 0 ? filterParams.modalidad_remoto : undefined
      const activeSearch = filterParams.activeSearch
      const selectedJornada = filterParams.jornada && filterParams.jornada?.length > 0 ? filterParams.jornada : undefined
      const selectedExcluirExperienciaLaboral = filterParams.excluirExperienciaLaboral && filterParams.excluirExperienciaLaboral?.length > 0 ? filterParams.excluirExperienciaLaboral : undefined
      const selectedExcluirEmpresa = filterParams.excluirEmpresa && filterParams.excluirEmpresa?.length > 0 ? filterParams.excluirEmpresa : undefined

      dispatch(GetPostulacionesByOfertaReducer(
        offerId,
        nextPage,
        rex_limit,
        selectedRatings,
        selectedExperienciaLaboral,
        selectedSoftSkills,
        selectedPaises,
        selectedCiudades,
        selectedCompanies,
        selectedExperiences,
        selectedEducations,
        selectedSectors,
        selectedValoracionesMax,
        selectedExperienciaNula,
        selectedModalidadRemoto,
        selectedExcluirExperienciaLaboral,
        activeSearch,
        selectedJornada,
        selectedExcluirEmpresa,
      )).then(() => {
        setPage(nextPage);
        setLoadingMoreData(false);
        if (nextPage >= totalPages) {
          setHasMore(false);
        }
      })
        .catch(() => {
          setLoadingMoreData(false);
        });
    }, 1000);
  };

  const showModalGraph = () => {
    const existUser = rex_users_graph_sk.users.find(usr => usr.id != 0)

    if(!existUser){
      message.warning('Debe seleccionar a algun usuario')
      return
    }
    setShowSoftSkills(true)
  }

  return (
    //BASE
    <div className="flex justify-center">
      <div className="w-full 2x2:w-page-width">
        <div className="px-4 lg:px-8 py-8 lg:py-12">

          <div className="flex flex-col lg:flex-row">
            <Button
              type="primary"
              className="lg:hidden mb-4"
              onClick={() => setDrawerVisible(true)}
              icon={<MenuOutlined />}
            />
            <div style={{ width: "234px", backgroundColor: "white" }} className="hidden lg:block p-0">
              <SidebarOffers
                activeSearch={activeSearch}
                setActiveSearch={setActiveSearch}
                onFilterChange={handleFilterChange}
                resetFilters={handleResetFilters}
              />
            </div>

            <Drawer
              title={null}
              drawerStyle={{ padding: 0 }}
              width={268}
              placement="left"
              onClose={() => setDrawerVisible(false)}
              open={drawerVisible}
              className="block lg:hidden"
            >
              <SidebarOffers
                inDrawer
                activeSearch={activeSearch}
                setActiveSearch={setActiveSearch}
                onFilterChange={handleFilterChange}
                resetFilters={handleResetFilters}
              />
            </Drawer>

            <div className="flex-1 pl-0 md:pl-[24px]">
              <Row gutter={[16, 16]}>
                <Col sm={24} md={24}>
                  <div className="mb-6">
                    <div className="flex justify-between items-center">
                      <div>
                        <h2
                          onClick={() => navigate('/offers')}
                          className="font-bold text-[#006497] text-2xl inline cursor-pointer"
                        >
                          Oferta
                        </h2>
                        <span className="font-bold text-[#1A1A1A70] text-2xl mx-2">&gt;</span>
                        <span className="font-bold text-black text-2xl inline">Listado de inscritos en la oferta</span>
                      </div>
                    </div>
                    <Divider className="my-4 bg-[#A1A1AA]" />
                    <div className="flex justify-between items-center mb-4">
                      <div>
                        <h3 className="text-sm font-medium font-sans text-black">Título de la oferta</h3>
                        <p className="text-lg font-medium font-sans text-black">{nombreOferta}</p>
                      </div>
                      {/* <Button
                          className="principal-nav-notify-button w-[150px] h-[36px] font-semibold text-body-sm rounded-[4px]"
                          onClick={() => navigate("")}
                          disabled
                        >
                          Guardar búsqueda
                        </Button>*/}
                    </div>
                    <Row gutter={16} className="mb-[30px]">
                      <Col xs={24} md={24} lg={24} className="">
                        <Row gutter={24} className="flex justify-between">
                          <Col>
                            <span className="font-bold font-sans text-[#5F5F5F] text-xs">Ordenado por:</span>
                            <div className="mt-2">
                              <span className="font-bold text-green32 text-xs cursor-not-allowed">
                                RECOMENDACIÓN IA
                              </span>
                              <span className="font-bold text-black text-xs mx-2">|</span>
                              <span className="font-bold text-[#00476D] text-xs">FECHA DE INSCRIPCIÓN</span>
                            </div>
                          </Col>
                          <Col className="flex justify-end items-center">
                            <IconButton
                              icon={Stream}
                              selected={selectedView === "stream"}
                              onClick={() => handleViewSelect("stream")}
                              altText="Icono de flujo"
                            />
                            <IconButton
                              icon={List}
                              selected={selectedView === "list"}
                              onClick={() => handleViewSelect("list")}
                              altText="Icono de lista"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row >

                    <div className="flex justify-between items-center mt-6 -mb-2">
                      <Row gutter={[0, 6]} className="flex justify-between w-full">
                        <Col lg={19} className="">
                          <Row gutter={[6, 6]} className="h-full flex items-center">
                            <Col className="flex content-center w-full md:w-auto h-min">
                              <span className="text-[#1A1A1A] text-[12px] font-bold opacity-70 flex items-center justify-center">
                                ACCIONES DE PERFIL:
                              </span>
                            </Col>
                            <Col className="flex content-center justify-center w-2/4 md:w-auto border-r-[3px] border-black h-min">
                              <a href="/" className="text-[#00476D] font-bold text-[12px] px-2 flex items-center justify-center">
                                Añadir a favoritos
                              </a>
                            </Col>
                            <Col className="flex content-center justify-center border-none md:border-solid w-2/4 md:w-auto border-r-[3px] md:border-black h-min">
                              <Dropdown overlay={phaseMenu} trigger={['click']} disabled={rex_loadingp}>
                                <a className="text-[#00476D] font-bold text-[12px] cursor-pointer px-2 flex items-center justify-center">
                                  Avanzar en la fase
                                </a>
                              </Dropdown>
                            </Col>
                            <Col className="flex content-center justify-center w-2/4 md:w-auto border-r-[3px] border-black h-min">
                              <a href="/" className="text-[#00476D] font-bold text-[12px] px-2 flex items-center justify-center">
                                Guardar perfil
                              </a>
                            </Col>
                            <Col className="flex content-center justify-center w-2/4 md:w-auto h-min">
                              <a href="/" className="text-[#DC2828] font-bold text-[12px] px-2 flex items-center justify-center">
                                Archivar candidato
                              </a>
                            </Col>
                          </Row>
                        </Col>

                        <Col xs={24} lg={5} className="">
                          <button
                            className="text-[#00476D] font-bold text-xs ml-auto"
                            onClick={showModalGraph}
                          >
                            Ver candidatos seleccionados sobre gráfica
                          </button>
                        </Col>
                      </Row>
                    </div>

                  </div >

                  {
                    loading ?
                      <div>
                        <PostulationSkeleton />
                        <PostulationSkeleton />
                        <PostulationSkeleton />
                      </div>
                      : selectedView === "stream" ? (
                        <Card className="border-[#81BFEC] bg-[#FBFDFE] rounded-md p-4 mb-4">
                          <OffersProfileInfo
                            offerId={offerId}
                            fetchMoreData={fetchMoreData}
                            hasMore={hasMore}
                          />
                        </Card>
                      ) : (
                        <div className="">
                          <TableProfile
                            offerId={offerId}
                            fetchMoreData={fetchMoreData}
                            hasMore={hasMore}
                          />
                        </div>
                      )
                  }
                </Col >
              </Row>
            </div>
          </div>

          <ModalSoftSkills
            visible={showSoftSkills}
            onClose={() => setShowSoftSkills(false)}
            data={[]}
          />
        </div >
      </div>
    </div>
  );
};

export default OffersProfile;