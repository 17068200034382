import React from 'react'
import { Skeleton } from 'antd'

const PostulationSkeleton = () => {
	return (
		<div className="p-6 bg-gray-100 rounded-lg relative mb-8">
			<div className="flex flex-wrap -mx-3 relative">
				<div className="w-full md:w-3/4 px-3 relative">
					<div className="flex items-center mb-4">
						<Skeleton.Avatar active size={64} shape="square" />
						<div className="ml-4 flex-grow">
							<Skeleton.Input style={{ width: '60%' }} active size="small" />
							<Skeleton.Input style={{ width: '40%' }} active size="small" />
						</div>
					</div>
					<Skeleton active paragraph={{ rows: 2 }} />
					<Skeleton active paragraph={{ rows: 2 }} />
					<Skeleton active paragraph={{ rows: 2 }} />
					<Skeleton active paragraph={{ rows: 1 }} />
				</div>
				<div className="w-full md:w-1/4 px-3 mt-2 md:mt-0 relative">
					<div style={{ height: '200px' }}></div>
				</div>
			</div>
		</div>
	)
}

export default PostulationSkeleton