export const FETCH_POSTULACIONES_REQUEST  = 'FETCH_POSTULACIONES_REQUEST';
export const FETCH_POSTULACIONES_SUCCESS  = 'FETCH_POSTULACIONES_SUCCESS';
export const FETCH_POSTULACIONES_FAILURE  = 'FETCH_POSTULACIONES_FAILURE';
export const UPDATE_DATA_SOFT_SKILLS_OFFER  = 'UPDATE_DATA_SOFT_SKILLS_OFFER';
export const UPDATE_DATA_POSTULACIONES    = 'UPDATE_DATA_POSTULACIONES';
export const UPDATE_USERS_GRAPH_SK    = 'UPDATE_USERS_GRAPH_SK';
export const UPDATE_CV_OR_VE_REQUEST  = 'UPDATE_CV_OR_VE_REQUEST';
export const UPDATE_CV_OR_VE_SUCCESS  = 'UPDATE_CV_OR_VES_SUCCESS';
export const UPDATE_CV_OR_VE_FAILURE  = 'UPDATE_CV_OR_VE_FAILURE';
export const SET_FILTER_PARAMS = 'SET_FILTER_PARAMS';

export interface FasePostulacion {
  fase: string;
  fecha?: string;  // Si tiene más propiedades
}

export interface DataSoftSkill {
  [key: string]: string | number | undefined;
  subject: string;
  idxSubject?: number,
  A?: number;
  B?: number;
  C?: number;
  D?: number;
  E?: number;
  F?: number;
  G?: number;
  H?: number;
  I?: number;
  J?: number;
  fullMark: number;
}

export interface DetailSoftSkillsFormat { 
  soft_skills: { 
    soft_skill: any
    soft_skill_c: any 
  }
  nivel: number 
}

interface UserGraph {
  nombre:string;
  id:number;
  nameKey:string
}

export interface LabelsGraphSoftSkills {
  id: number,
  nameKey: string,
  color: string
}

export interface Postulacion {
  checked : boolean,
  usuarios: {
    id: number;
    usuario: string;
    imagen: string | null;
    ubicacion: string;
    cargo: string;
    tipos_usuarios: {
      id: number;
      tipo_usuario: string;
    };
    soft_skills_usuarios: [
      {
        soft_skills : {
          soft_skill: string
        },
        nivel: number,
        porcentaje: number
      }
]
    personas: {
      apellido_materno: string;
      apellido_paterno: string;
      nombre: string;
    };
    
    meses_experiencia: number;
    experiencias_laborales_usuarios: Array<{
      id: number;
      empresa_id: number;
      usuario_id: number;
      sector_id: number;
      cargo: string;
      descripcion: string;
      nombre_empresa: string;
      fecha_inicio: string;
      fecha_fin: string;
      nombre_sector: string;
    }>;
    educacion_usuarios: Array<{
      id: number;
      tipo_educacion_id: number;
      centro_educativo_id: number;
      usuario_id: number;
      carrera_id: number;
      fecha_inicio: string;
      fecha_final: string;
      nombre_centro_educativo: string;
      carrera: string;
      ubicacion: string;
    }>;
    cvs_usuarios: Array<{
      id: number;
      usuario_id: number;
      nombre: string;
      nombre_archivo: string;
      cv: string;
      default: boolean;
    }>;
    aptitudes_usuarios: Array<{
      id: number;
      aptitud:string;
      usuario_id: number;
    }>;
    valoraciones_usuarios: Array<{
      valoracion: number;
    }>;
    promedioValoraciones: number;
  };
  ofertas: {
    id: number;
    sectores: {
      id: number;
      sector: string;
    };
  };
  fases_postulaciones?: FasePostulacion;
  id: number;
}

export interface FetchPostulacionesRequestAction {
  type: typeof FETCH_POSTULACIONES_REQUEST;
}

export interface UpdateDataPostulacionesAction {
  type: typeof UPDATE_DATA_POSTULACIONES;
  payload : {
    data: Postulacion[]
  }
}

export interface UpdateUsersGraphSkAction {
  type: typeof UPDATE_USERS_GRAPH_SK;
  payload : {
    data: { data : DataSoftSkill[], users:UserGraph[]}
  }
}

export interface UpdateCvOrVeRequestAction {
  type: typeof UPDATE_CV_OR_VE_REQUEST;
  payload: {
    postulacionId: number;
  };
}

export interface UpdateCvOrVeSuccessAction {
  type: typeof UPDATE_CV_OR_VE_SUCCESS;
  payload: {
    updatedPostulacion: Postulacion; 
  };
}

export interface UpdateCvOrVeFailureAction {
  type: typeof UPDATE_CV_OR_VE_FAILURE;
  payload: {
    error: string; 
  };
}

export interface FetchPostulacionesSuccessAction {
  type: typeof FETCH_POSTULACIONES_SUCCESS;
  payload: {
    data: Postulacion[];
    meta: { total: number; limit: number; page: number }; 
  };
}

export interface UpdateDataSoftSkillsOfferAction {
  type: typeof UPDATE_DATA_SOFT_SKILLS_OFFER;
  payload: {
    users: any[];
    data: any[];
  };
}

export interface FetchPostulacionesFailureAction {
  type: typeof FETCH_POSTULACIONES_FAILURE;
  payload: string;
}

interface SetFilterParamsAction {
  type: typeof SET_FILTER_PARAMS;
  payload: any; 
}

export type PostulacionesActionTypes =
  | FetchPostulacionesRequestAction
  | FetchPostulacionesSuccessAction
  | FetchPostulacionesFailureAction
  | UpdateDataPostulacionesAction
  | UpdateDataSoftSkillsOfferAction
  | UpdateCvOrVeRequestAction
  | UpdateCvOrVeSuccessAction
  | UpdateCvOrVeFailureAction
  | SetFilterParamsAction
  | UpdateUsersGraphSkAction;