import React, { useEffect, useState } from "react";
import {
  Input,
  Row,
  Col,
  // Select
} from "antd";
import IconSearch from "../../assets/icons/search.svg";
import IconRecommendations from "../../assets/icons/recommendations.svg";
import IconAlerts from "../../assets/icons/alerts.svg";
import IconResults from "../../assets/icons/results.svg";
import { useNavigate, Routes, Route, useLocation } from "react-router-dom";
import Recommendations from "./recommendations/Recommendations";
import YourAlerts from "./yourAlerts/YourAlerts";
import SearchResult from "./searchResult/SearchResult";
import "../../styles/pages/employment/Employment.css";
import "../../styles/pages/employment/FiltersEmployment.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store/store"; // Ajusta la ruta según tu estructura
import { GetSectorsReducer } from "../../redux/actions/common/sectors/Sectors";
import DebounceSelect from "../../components/pages/offers/CreateOffers/Select";
// import { SearchOffersEmplReducer } from "../../redux/actions/offers/SearchOffers";
import { GetPaisesReducer } from "../../redux/actions/pages/emplotment/searchCountry";
import { Button } from "antd/lib";
import SelectBasic from "../../components/pages/offers/CreateOffers/SelectBasic";
import {
  CloseCircleOutlined,
  // SearchOutlined
} from "@ant-design/icons";

const EmpleoRecomendaciones: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const sectors = useSelector((state: RootState) => state.sectors.rex_sectors);
  const paises = useSelector((state: RootState) => state.paises.rex_paises);
  // const loadin = useSelector((state: RootState) => state.sectors.rex_loading);
  const loading = useSelector((state: RootState) => state.paises.rex_loading);

  // const [page, setPage] = useState(1);
  // const [hasMore, setHasMore] = useState(true);

  const [searchTerm, setSearchTerm] = useState<string>("");
  // const [searchSector, setSearchSector] = useState<{ label: string; value: number } | null>(null);
  const [searchSector, setSearchSector] = useState<any>(null);
  // const [searchPais, setSearchPais] = useState<string>("");
  const [searchCiudad, setSearchCiudad] = useState<any>("");


  const [clickedInput] = useState<string | null>(null);
  const [selectedSectores, setSelectedSectores] = useState<string | null>(null);

  // New states for country/city
  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  // const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [availableCities, setAvailableCities] = useState<any[]>([]);
  // const [searchExecuted, setSearchExecuted] = useState<boolean>(false);

  const [searchQuery, setSearchQuery] = useState<any | null>(null);



  useEffect(() => {
    // Fetch countries when component mounts
    dispatch(GetPaisesReducer());
  }, [dispatch, selectedCountry]);

  useEffect(() => {
    if (selectedCountry && paises.length > 0) {
      const country = paises.find((c: any) => c.id === selectedCountry);
      if (country) {
        setAvailableCities(country.ciudades);
      } else {
        setAvailableCities([]);
      }
    } else {
      setAvailableCities([]);
    }
  }, [selectedCountry, paises]);



  // const loadMoreSectors = async () => {
  //   if (!hasMore || loadin) return;

  //   const result = await dispatch(GetSectorsReducer("", page, 10));
  //   if (result.payload && result.payload.meta) {
  //     setHasMore(page < result.payload.meta.totalPages);
  //     setPage((prevPage) => prevPage + 1);
  //   }
  // };

  // const handleInputClick = (name: string) => {
  //   setClickedInput((prev) => (prev === name ? null : name));
  // };

  const handleSearch = (e?: React.KeyboardEvent | React.MouseEvent) => {
    // navigate("/employment/search-result", {
    //   state: { selectedCountry, selectedSectores, selectedCity: searchCiudad },
    // });    
    if (e && "key" in e && e.key !== "Enter") return;
    setSearchQuery({
      searchTerm,
      selectedCountry,
      selectedSectores,
      searchCiudad,
    });
    navigate("/employment/search-result");

  };

  // const handleSearchOnEnter = (e: React.KeyboardEvent) => {
  //   if (e.key === "Enter") {
  //     // Envía la búsqueda cuando se presiona Enter
  //     setSearchQuery({ searchTerm, selectedCountry,selectedSectores ,searchCiudad});
  //     navigate("/employment/search-result");
  //   }
  // };

  const clearSector = () => setSearchSector(null);
  const clearCountry = () => {
    setSelectedCountry(null);
    setSearchCiudad(null)
    setAvailableCities([])
    setSearchQuery({ selectedCountry: null, selectedSectores, searchCiudad: null });
  };

  const clearSectors = () => {
    setSelectedSectores(null)
    setSearchQuery({ selectedCountry, selectedSectores: null, searchCiudad });

  }



  return (
    <div className="flex justify-center">
      <div className="w-full 2x2:w-page-width">
        {/* <Col xs={24} sm={20} md={15}> */}
          <div
            className="px-[20px]"
          >
            <Row
              gutter={[12, 12]}
            // className="px-2"
            >
              <Col sm={24} md={12} lg={5} className="w-full">
                <Input
                  prefix={<img src={IconSearch} alt="icon-search-emploment" width={24} height={24} />}
                  placeholder="Buscar empleo de..."
                  className={
                    `Input-Filter-Employment font-sans font-medium text-base md:mr-[8px] ${clickedInput === "search" ? "clicked" : ""}`
                  }
                  // onClick={() => handleInputClick("search")}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={handleSearch}
                />
              </Col>

              <Col xs={12} sm={12} md={12} lg={5} xxl={4}>
                <DebounceSelect
                  showSearch
                  placeholder="Sector (Opcional)"
                  size="large"
                  value={searchSector || undefined}
                  onChange={(newValue: any) => {
                    setSearchSector(newValue)
                    const selectedItems = Array.isArray(newValue)
                      ? newValue
                      : [newValue];
                    const selectedLabels = selectedItems.map(
                      (item: any) => item.label || item.value
                    );
                    setSelectedSectores(selectedLabels[0])
                  }}
                  fetchOptions={async (search) => {
                    const response = await dispatch(
                      GetSectorsReducer(search, 1, 5)
                    );
                    return response.payload.data.map((sector: any) => ({
                      label: sector.sector,
                      value: sector.id,
                    }));
                  }}
                  suffixIcon={
                    searchSector ? (
                      <CloseCircleOutlined
                        onClick={() => {
                          clearSector();
                          clearSectors();
                        }}
                        style={{ color: "black", cursor: "pointer" }}
                      />
                    ) : undefined
                  }
                  style={{ width: "100%", height: "44px" }}
                />
              </Col>
              <Col xs={12} sm={12} md={12} lg={5} xxl={4}>
                <SelectBasic
                  placeholder="País"
                  style={{ width: "100%", height: '44px' }}
                  value={selectedCountry || undefined}
                  onChange={(value: any) => {
                    setSelectedCountry(value)
                  }}
                  loading={loading}
                  suffixIcon={
                    selectedCountry ? (
                      <CloseCircleOutlined
                        onClick={clearCountry}
                        style={{ color: "black", cursor: "pointer" }}
                      />
                    ) : undefined
                  }

                  options={paises.map((country: any) => ({
                    value: country.id,
                    label: country.pais
                  }))}
                />
              </Col>
              <Col xs={12} sm={12} md={12} lg={4} xxl={4}>
                <SelectBasic
                  placeholder="Ciudad"
                  style={{ width: "100%", height: '44px' }}
                  disabled={!selectedCountry}
                  options={availableCities.map((city: any) => ({
                    value: city.ciudad,
                    label: city.ciudad
                  }))}
                  onChange={(citie) => {
                    setSearchCiudad(citie)
                  }}
                  value={searchCiudad || undefined}
                />
              </Col>
              <Col xs={12} sm={12} md={24} lg={5} xxl={2}>
                <div
                  className={`Button-Filter-Employment h-[44px] ${clickedInput === "button" ? "clicked" : ""
                    }`}
                  onClick={handleSearch}
                >
                  Buscar
                </div>


              </Col>
            </Row>

          </div>

          {/* Barra de búsqueda */}
          <div className="search-bar-merere">
            <Row gutter={5} align="middle" style={{ width: "100%" }}>
              <Col xl={24} md={24}>
                <div style={{ display: "flex" }}>
                  {/* <div>
                    <Input
                      prefix={<img src={IconSearch} width={24} height={24} />}
                      placeholder="Buscar empleo de..."
                      className={
                        `Input-Filter-Employment w-[418px] mr-[8px] ${clickedInput === "search" ? "clicked" : ""}`
                      }
                      onClick={() => handleInputClick("search")}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div> */}
                  {/* <div>
                    <DebounceSelect
                      showSearch
                      placeholder="Sector (Opcional)"
                      size="large"
                      fetchOptions={async (search) => {
                        const response = await dispatch(
                          GetSectorsReducer(search, 1, 5)
                        );
                        return response.payload.data.map((sector: any) => ({
                          label: sector.sector,
                          value: sector.id,
                        }));
                      }}
                      onChange={(newValue) => {
                        setSearchSector(newValue)
                        const selectedItems = Array.isArray(newValue)
                          ? newValue
                          : [newValue];
                        const selectedLabels = selectedItems.map(
                          (item: any) => item.label || item.value
                        );
                        setSelectedSectores((prevSelected) =>
                          Array.from(new Set([...prevSelected, ...selectedLabels]))
                        );
                      }}
                      style={{ width: "225px", height: "44px" }}
                    />
                  </div> */}

                  {/* <div
                    className="ml-2"
                  >
                    <SelectBasic
                      placeholder="País"
                      style={{ width: "200px", height: '44px' }}
                      onChange={(value) => setSelectedCountry(value)}
                      loading={loading}
                      options={paises.map((country: any) => ({
                        value: country.pais,
                        label: country.pais
                      }))}
                    />
                  </div> */}
                  {/* <div className="ml-2 mr-2">
                    <SelectBasic
                      placeholder="Ciudad"
                      style={{ width: "200px", height: '44px' }}
                      disabled={!selectedCountry}
                      options={availableCities.map((city: any) => ({
                        value: city.ciudad,
                        label: city.ciudad
                      }))}
                      onChange={(citie) => {
                        setSearchCiudad(citie)
                      }}
                    />
                  </div> */}
                  {/* <div>
                    <div
                      className={`Button-Filter-Employment h-[44px] ${clickedInput === "button" ? "clicked" : ""
                        }`}
                      onClick={handleSearch}
                    >
                      Buscar
                    </div>
                  </div> */}
                </div>
              </Col>
            </Row>
          </div>

          {/* Tabs de navegación */}
          <div className="tabs-merere mt-0">
            <div className="tab-links-merere flex justify-center px-4">
              <button
                className={`tab-link-merere ${location.pathname === "/employment/recommendations"
                  ? "active-merere"
                  : ""
                  }`}
                onClick={() => navigate("/employment/recommendations")}
              >
                <img src={IconRecommendations} alt="icon-recommendations-emploment" className="icon-merere" />{" "}
                <span className="inline font-sans font-semibold text-lg">Recomendaciones</span>
              </button>
              <button
                className={`tab-link-merere ${location.pathname === "/employment/your-alerts"
                  ? "active-merere"
                  : ""
                  }`}
                // onClick={() => navigate("/employment/your-alerts")}
                style={{ cursor: 'not-allowed' }}
              >
                <img src={IconAlerts} alt="icon-alert-emploment" className="icon-merere" />{" "}
                <span className="inline font-sans font-semibold text-lg">Tus alertas</span>
              </button>
              <button
                className={`tab-link-merere ${location.pathname === "/employment/search-result"
                  ? "active-merere"
                  : ""
                  }`}
                onClick={() => navigate("/employment/search-result")}
              >
                <img alt="icon-results-emploment" src={IconResults} className="icon-merere" />{" "}
                <span className="inline font-sans font-semibold text-lg">Resultado de tu búsqueda</span>
              </button>
            </div>
            {/* Contenido de las tabs */}
            <div className="tab-content-merere p-4">
              <Routes>
                <Route path="recommendations" element={<Recommendations />} />
                <Route path="your-alerts" element={<YourAlerts />} />

                <Route
                  path="search-result"
                  element={<SearchResult searchQuery={searchQuery || 'Cargando...'} />}
                />

              </Routes>
            </div>
          </div>
        {/* </Col> */}
      </div>
    </div>
  );
};

export default EmpleoRecomendaciones;