import React, { useState } from 'react';
import { Card, Avatar, Button, Skeleton, Row, Col, Tooltip, message, Modal } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import 'tailwindcss/tailwind.css';
import IconLocation2 from '../../assets/icons/location2.svg';
import IconTypemoney1 from '../../assets/icons/typemoney1.svg';
import IconClock from '../../assets/icons/clock.svg';
import IconUsers from '../../assets/icons/IconUsers.svg';
import IconB from '../../assets/icons/IconB.svg';
import IconCheck from '../../assets/icons/IconCheck.svg';
import IconC from '../../assets/icons/IconC.svg'
import IconSavedOffer from '../../assets/icons/saved.svg'
import DiferenciaFecha from '../../utils/CalculateDifferentTime';
import IconOfertas from '../../assets/icons/ofertas.svg';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../redux/store/store';
import { SignUpOfferReducer } from '../../redux/actions/offers/SignUpOffer';
import IconClosed from "../../assets/icons/IconClosed.svg";
import { useLocation, useNavigate } from 'react-router-dom';
import { GetApplicationsReducer } from '../../redux/actions/applications/GetApplications';
import { GetOfferSaveReducer } from '../../redux/actions/offers/GetOfferSave';
import CardStats from './CardStats';


const IMAGE_USER_DEFAULT = process.env.REACT_APP_IMAGE_EMPRESA_DEFAULT;
const API_BASE_URL_EXACT = process.env.REACT_APP_API_BASE_URL_EXACT;

const optionsWorkingDays = [
  { value: "todas", label: "Todas las modalidades de jornada" },
  { value: "completa", label: "Jornada completa" },
  { value: "indiferente", label: "Jornada indiferente" },
  { value: "parcial_maniana", label: "Jornada parcial - Turno mañana" },
  { value: "parcial_tarde", label: "Jornada parcial - Turno tarde" },
  { value: "parcial_noche", label: "Jornada parcial - Turno noche" },
  { value: "parcial_indiferente", label: "Jornada parcial - Horario indiferente" },
  { value: "intensiva_maniana", label: "Jornada intensiva - Turno mañana" },
  { value: "intensiva_tarde", label: "Jornada intensiva - Turno tarde" },
  { value: "intensiva_noche", label: "Jornada intensiva - Turno noche" },
  { value: "intensiva_indiferente", label: "Jornada intensiva - Horario indiferente" }
]

interface CardEmpleoProps {
  companyName: string;
  jobTitle: string;
  location: string;
  salary: string;
  schedule: string;
  ratings: string;
  activeOffers: string;
  followers: string;
  description: string;
  postedTime: string;
  applied: boolean;
  avatarSize?: number;
  // avatarShape?: 'circle' | 'square';
  avatarShape?: string;
  avatar?: string;
  // styleType?: 'default' | 'type1' | 'type2' | 'type4';
  styleType?: string;
  loading?: boolean;
  stepsComponent?: React.ReactNode; // New prop
  currentStep: number; // New prop, required
  createdAt?: string;
  saveOffer?: boolean;
  dataComplete: any;
  index? : number;
  setSelectedCardIndex?: any;
  idCard? : number;
  indexSelected? : number | null
}

const CardEmpleo: React.FC<CardEmpleoProps> = ({
  companyName,
  jobTitle,
  location,
  salary,
  schedule,
  ratings,
  activeOffers,
  followers,
  indexSelected,
  description,
  postedTime,
  avatarSize = 40,
  // avatarShape = 'circle',
  avatarShape,
  avatar = null,
  applied,
  styleType = 'default',
  loading = false,
  stepsComponent,
  currentStep,
  createdAt,
  saveOffer = false,
  dataComplete,
  setSelectedCardIndex,
  index,
  idCard
}: any) => {
  const formattedDescription = description?.replace(/(?:\r\n|\r|\n)/g, '<br>');
  const {
    rex_phases,
    rex_loadingp
  } = useSelector(({ getApplicationPhases }: any) => getApplicationPhases);

  const dispatch = useDispatch<AppDispatch>()
  const [ showModalCancelApplication, setShowModalCancelApplication] = useState<boolean>(false)
  const [ idSelected, setIdSelected ] = useState<number | null>(null)

  const locationPage = useLocation();
  const navigate = useNavigate()

  const getDataApplications = () => {
    if(locationPage.pathname == '/myApplications/applications'){
      dispatch(GetApplicationsReducer() as any)
    }else if(locationPage.pathname == '/myApplications/saved'){
      dispatch(GetOfferSaveReducer() as any);
    }else if(locationPage.pathname === '/myApplications/saved'){

    }
  }

  const cancelApplication = async () => {
    if(idSelected){
      const rpta:any = await dispatch(SignUpOfferReducer(idSelected, null, null, null))
      if(rpta.respuesta){
        getDataApplications()
        setSelectedCardIndex(null)
        message.success('Postulacion eliminada');
        setShowModalCancelApplication(false)
      }else{
        message.error('Error, operacion no realizada');
      }
      return
    }
    message.error('Error, operacion no realizada');
  }

  const getLabelWorkingDay = (value: string) => {
    const findWorkingDay = optionsWorkingDays.find((item) => item.value === value)
    return findWorkingDay?.label || ''
  }

  return (
    <div className={`w-full flex justify-center`}>
      <Card
        bordered={false}
        className={`w-full h-auto rounded-lg bg-[#FCFCFC] pt-3 ${styleType === 'type4' ? 'pb-0' : ''} ${indexSelected != index ? 'cursor-pointer' : 'cursor-auto'}`}
        style={{ boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.1)' }}
      >
        {loading ? (
          <Skeleton avatar active paragraph={{ rows: 4 }} />
        ) : (
          <>
            {
              applied
                ? <div className="absolute left-0 top-0 bottom-0 w-2 bg-[#FDBCB4] rounded-l-lg"></div>
                : null
            }
            <div className="flex items-center justify-between mb-2 -mt-7">
              <div className="flex items-center">
                <Avatar
                  size={avatarSize}
                  src={
                    avatar
                      ? API_BASE_URL_EXACT + avatar
                      : IMAGE_USER_DEFAULT
                    // avatar !== null
                    //   ? avatar
                    //   : IconUsers
                  }
                  shape={avatarShape}
                />
                <div className="ml-3">
                  <h3 className="text-[14px] font-medium m-0 font-sans">{companyName}</h3>
                  <h4 className="text-base m-0 font-bold font-sans">{jobTitle}</h4>
                </div>
              </div>
              <div 
                className={`text-xs text-gray-500 ${!saveOffer && styleType == "type4" ? 'hover:border rounded p-2 text-sm font-sans font-medium text-[#1A1A1AB2] ease-in duration-200 cursor-pointer':''} text-center`}
                onClick={async (e)=>{
                  e.stopPropagation()
                  if(!saveOffer && styleType == "type4"){
                    setIdSelected(idCard)
                    setShowModalCancelApplication(true)
                  }
                }}
              >
                {
                  saveOffer
                    ? <img src={IconSavedOffer} width="22" height="22" className="text-sm" />
                    : styleType === 'type4'
                      ? 'Ya no estoy interesado(a)'
                      : styleType === 'type3'
                        ? <img src={IconSavedOffer} width="22" height="22" className="text-sm" />
                        : <img src={IconC} width="22" height="22" className="text-lg" />
                }
              </div>
            </div>
            <div className="flex flex-wrap justify-start items-center mb-2 space-x-4">
              <div className="flex items-center text-[14px] font-medium font-sans"><img src={IconLocation2} className="p-1" />{location}</div>
              <div className="flex items-center text-[14px] font-medium font-sans"><img src={IconTypemoney1} className="p-1" /> {salary}</div>
              <div className="flex items-center text-[14px] font-medium font-sans"><img src={IconClock} className="p-1" />{getLabelWorkingDay(schedule)}</div>
            </div>
            <div className="flex flex-wrap justify-start items-center mb-2 gap-1">
              <CardStats
                text={ratings}
                iconButton={<img src={IconCheck} />}
              />
              <CardStats
                text={activeOffers}
                iconButton={<img src={IconB} />}
              />
              <CardStats
                text={followers}
                iconButton={<img src={IconUsers} />}
              />
            </div>

            {
              styleType === 'type4' && (
                <div>
                  <Row>
                    <Col xxl={24} xl={24} md={24}>
                      <div
                        className='flex w-full'
                      >
                        <Col md={12} xxl={9} xl={9}>
                          <div
                            className='flex'
                            style={{
                              alignItems: 'center'
                            }}
                          >
                            <img
                              src={IconOfertas}
                              style={{
                                width: '24px',
                              }}
                              className='mr-1'
                            />
                            <span
                              style={{
                                fontFamily: 'Inter',
                                fontWeight: 'bold',
                                fontSize: '16px'
                              }}
                              className='mr-1'
                            >
                              Inscrito
                            </span>
                            <span
                              style={{
                                color: '#1A1A1A',
                                fontFamily: 'Inter',
                                fontWeight: 'Medium',
                                fontSize: '12px'
                              }}
                            >
                              <DiferenciaFecha fecha={dataComplete.createdAt} />
                              {/* Ahora mismo */}
                            </span>
                          </div>
                        </Col>
                        <Col
                           md={12} xxl={15} xl={15}
                          style={{ alignContent: 'center' }}
                        >
                          <div
                            className="progress-bar-container flex"
                            style={{
                              width: '100%'
                            }}
                          >
                            {
                              rex_phases.map((phase: any, index: number) => {
                                return (
                                  <Tooltip
                                    title={phase.fase}
                                  >
                                    <div
                                      className={`progress-bar h-[8px] rounded-full 
                                  ${index != 0
                                        && "ml-[10px]"
                                        }
                                  ${dataComplete.fases_postulaciones.prioridad >= phase.prioridad
                                          ? "bg-[#006497] w-[100px]"
                                          : "bg-[#E1E1E1] w-[100px]"
                                        }`}
                                    />
                                  </Tooltip>
                                )
                              })
                            }
                            {/* <div
                          className={`progress-bar h-[8px] rounded-full ${dataComplete.fases_postulaciones.prioridad >= 1
                            ? "bg-[#006497] w-[100px]"
                            : "bg-[#E1E1E1] w-[100px]"
                            }`}
                        />
                        <div
                          className={`progress-bar h-[8px] rounded-full ml-[10px] ${dataComplete.fases_postulaciones.prioridad >= 2
                            ? "bg-[#006497] w-[100px]"
                            : "bg-[#E1E1E1] w-[100px]"
                            }`}
                        />
                        <div
                          className={`progress-bar h-[8px] rounded-full ml-[10px] ${dataComplete.fases_postulaciones.prioridad >= 3
                            ? "bg-[#006497] w-[100px]"
                            : "bg-[#E1E1E1] w-[100px]"
                            }`}
                        />
                        <div
                          className={`progress-bar h-[8px] rounded-full ml-[10px] ${dataComplete.fases_postulaciones.prioridad >= 4
                            ? "bg-[#006497] w-[100px]"
                            : "bg-[#E1E1E1] w-[100px]"
                            }`}
                        />
                        <div
                          className={`progress-bar h-[8px] rounded-full ml-[10px] ${dataComplete.fases_postulaciones.prioridad >= 5
                            ? "bg-[#006497] w-[100px]"
                            : "bg-[#E1E1E1] w-[100px]"
                            }`}
                        /> */}
                          </div>
                        </Col>

                      </div>
                    </Col>
                  </Row>
                </div>
              )
            }

            {styleType === 'type4' ? (
              <>
                <div className='h-[49px] overflow-hidden line-clamp-[2]'>
                  <p className="text-sm h-[44px] font-sans font-medium mt-2 leading-[22px]" dangerouslySetInnerHTML={{ __html: formattedDescription }}></p>
                </div>
                <div className="text-xs text-gray-500 text-right font-medium mt-2">
                </div>
              </>
            ) : (
              <>
                <div className='h-[49px] overflow-hidden line-clamp-[2]'>
                  <p className="text-sm font-sans font-medium mt-2 leading-[22px]" dangerouslySetInnerHTML={{ __html: formattedDescription }}></p>
                </div>
                <div className="text-[#1A1A1AB2] font-sans text-xs text-right font-medium mt-2">
                  Subida <DiferenciaFecha fecha={createdAt}/>
                </div>
              </>
            )}
          </>
        )}
      </Card>
      <Modal
        visible={showModalCancelApplication}
        closable={false}
        footer={null}
        width={800}
      >
        <img
          src={IconClosed}
          alt="Cerrar"
          onClick={()=>setShowModalCancelApplication(false)}
          style={{
            position: "absolute",
            top: "34px",
            right: "34px",
            cursor: "pointer",
            width: "24px",
            height: "24px",
          }}
        />
        <Row gutter={[16, 24]} className='text-center mt-7'>
          <Col span={24}>
            <p className="font-bold text-2xl font-sans">Anular inscripción</p>
          </Col>
          <Col span={24}>
            <p className="font-sans font-medium text-sm">¿Está seguro de eliminar la postulación?</p>
          </Col>
        </Row>
        <Row className='flex justify-center mb-5'>
          <div className="flex justify-center mx-[114px] mt-[38px]">
            <Button
              onClick={()=>setShowModalCancelApplication(false)}
              className="button-cancel rounded-[4px] text-black2 bg-[#FCFCFC] font-sans font-semibold text-base w-[118px] h-[44px]"
            >
              Cancelar
            </Button>
            <Button
              onClick={cancelApplication}
              className={`button-accept active bg-blue3 text-base font-sans border-none text-white w-[110px] h-[44px] ml-[90px] rounded-[4px]`}
            >
              Aceptar
            </Button>
          </div>
        </Row>
      </Modal>
    </div>
  );
};

export default CardEmpleo;