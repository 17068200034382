import React, { useState } from "react";
import { Typography } from 'antd';

interface TruncateTextProps {
  text: string;
  customClassName?: string
}

const TruncateText: React.FC<TruncateTextProps> = ({ text, customClassName }) => {

  const [expanded, setExpanded] = useState(false);

  return (
    <Typography.Paragraph
      ellipsis={{
        rows:2,
        expandable: 'collapsible',
        expanded,
        onExpand: (_, info) => setExpanded(info.expanded),
        symbol: (
          <span style={{ color:'red !important' }}>
            {expanded ? "Ver menos" : "Ver más"}
          </span>
        ),
        
      }}
      className={`text-truncate ${customClassName}`}  
    >
      {text}
    </Typography.Paragraph>
  );
};

export default TruncateText;