import React, { useState, useEffect } from "react";
import { Modal, Button, Select, Input, Row, Col } from "antd";
import IconClosed from "../../../../assets/icons/IconClosed.svg";
import InputC from "../../../../components/pages/offers/KillerQuestions/ComponentsKillersQ/Input";
import ModalSaved from '../../myPortal/Modals/ModalSavedChanges';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store/store";
import { UpdateProfileReducer } from "../../../../redux/actions/pages/myPortal/profile/Profile";
import { ValidateTokenAuthReducer } from "../../../../redux/actions/auth/Auth";
import SelectBasic from "../../offers/CreateOffers/SelectBasic";
import { GetPaisesReducer } from "../../../../redux/actions/pages/emplotment/searchCountry";

interface ModalEditProfileProps {
  visible: boolean;
  onClose: () => void;
  context: "myPortal" | "OfferRegistration";
}

const ModalEditProfile: React.FC<ModalEditProfileProps> = ({
  visible, onClose, context
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { rex_user } = useSelector(({ auth }: any) => auth);
  const { rex_loading, rex_error } = useSelector(({ profile }: any) => profile);
  const paises = useSelector((state: RootState) => state.paises.rex_paises);
  const loading = useSelector((state: RootState) => state.paises.rex_loading);
  const [formData, setFormData] = useState<Record<string, any>>({
    "nombre": rex_user?.personas?.nombre,
    "apellido_paterno": rex_user?.personas?.apellido_paterno,
    "apellido_materno": rex_user?.personas?.apellido_materno,
    "jornada": rex_user?.jornada,
    "modalidad": rex_user?.modalidad,
    "cargo": rex_user?.cargo || "",
    "pais_id": rex_user?.pais_id || 0,
    "ciudad": rex_user?.ciudad || "",
    "nivel_academico": rex_user?.nivel_academico || ""
  });

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSecondModalVisible, setIsSecondModalVisible] = useState(false);
  const [isAddingNewCountry, setIsAddingNewCountry] = useState(false);
  const [newCountry, setNewCountry] = useState("");
  const [selectedCountry, setSelectedCountry] = useState<string | null>("");
  const [availableCities, setAvailableCities] = useState<any[]>([]);
  const [searchCiudad, setSearchCiudad] = useState<string | null>("");

  const fieldConfigs = context === "myPortal"
    ? [
      { name: 'nombre', label: 'Nombre', value: '' },
      { name: 'apellido_paterno', label: 'Apellido(s)', value: '' },
      // { name: 'apellido_materno', label: 'Segundo Apellido', value: '' },
      { 
        name: 'nivel_academico', 
        label: 'Nivel Académico', 
        value: '',
        type: 'select',
        values: [
          { value: "eso", label: "Educación Secundaria Obligatoria" },
          { value: "bachillerato", label: "Bachillerato" },
          { value: "ciclo_medio", label: "Ciclo Formativo Grado Medio" },
          { value: "ciclo_superior", label: "Ciclo Formativo Grado Superior" },
          { value: "artisticas", label: "Enseñanzas artísticas (regladas)" },
          { value: "deportivas", label: "Enseñanzas deportivas (regladas)" },
          { value: "grado", label: "Grado" },
          { value: "licenciatura", label: "Licenciatura" },
          { value: "diplomatura", label: "Diplomatura" },
          { value: "ingenieria_tecnica", label: "Ingeniería Técnica" },
          { value: "ingenieria_superior", label: "Ingeniería Superior" },
          { value: "postgrado", label: "Postgrado" },
          { value: "master", label: "Máster" },
          { value: "doctorado", label: "Doctorado" },
          { value: "otros", label: "Otros títulos, certificaciones y carnés" },
          { value: "fp_grado_medio", label: "Formación Profesional Grado Medio" },
          { value: "fp_grado_superior", label: "Formación Profesional Grado Superior" }
        ]
      },
      {
        name: 'jornada', 
        label: 'Preferencia de jornada laboral', 
        value: '',
        type: 'select',
        values: [
          { value: "todas", label: "Todas las modalidades de jornada" },
          { value: "completa", label: "Jornada completa" },
          { value: "indiferente", label: "Jornada indiferente" },
          { value: "parcial_maniana", label: "Jornada parcial - Turno mañana" },
          { value: "parcial_tarde", label: "Jornada parcial - Turno tarde" },
          { value: "parcial_noche", label: "Jornada parcial - Turno noche" },
          { value: "parcial_indiferente", label: "Jornada parcial - Horario indiferente" },
          { value: "intensiva_maniana", label: "Jornada intensiva - Turno mañana" },
          { value: "intensiva_tarde", label: "Jornada intensiva - Turno tarde" },
          { value: "intensiva_noche", label: "Jornada intensiva - Turno noche" },
          { value: "intensiva_indiferente", label: "Jornada intensiva - Horario indiferente" }
        ]
      },
      {
        name: 'modalidad', 
        label: 'Preferencia de modalidad', 
        value: '', 
        type: 'select',
        values: [
          { value: "Presencial", label: "Presencial" },
          { value: "Híbrido", label: "Híbrido" },
          { value: "Remoto", label: "Remoto" },
        ]
      },
      { name: 'cargo', label: 'Puesto', value: '' },
    ]
    : [
      { name: 'fullName', label: 'Nombre Completo', value: '' },
      { name: 'email', label: 'Correo electrónico', value: '' },
      { name: 'cityState', label: 'Ciudad/Estado', value: '' },
      { name: 'phoneNumber', label: 'Número de teléfono', value: '' },
    ];

  const handleInputChange = (e: string, name: string) => {
    setFormData({
      ...formData,
      [name]: e,
    });
  };

  useEffect(() => {
    const allFieldsFilled = fieldConfigs.every(field => formData[field.name]?.length > 0);
    setIsSubmitDisabled(!allFieldsFilled || !formData.pais_id);
  }, [formData, fieldConfigs]);

  useEffect(() => {
    dispatch(GetPaisesReducer());
  }, []);

  useEffect(() => {
    if (selectedCountry && paises.length > 0) {
      const country = paises.find((c: any) => c.pais === selectedCountry);
      if (country) {
        setAvailableCities(country.ciudades);
      } else {
        setAvailableCities([]);
      }
    } else {
      setAvailableCities([]);
    }
  }, [selectedCountry, paises]);

  const handleSaveChanges = async () => {
    if (!isSubmitDisabled) {
      const personas = { personas: formData.nombre }
      await dispatch(UpdateProfileReducer(formData));
      if (!rex_error) {
        await dispatch(ValidateTokenAuthReducer());
      }
      onClose();
      setIsSecondModalVisible(true);
    }
  };

  return (
    <>
      <Modal
        open={visible}
        onCancel={onClose}
        footer={null}
        centered
        closable={false}
        width={677}
        bodyStyle={{ borderRadius: "12px" }}
        style={{ borderRadius: "12px", border: "1px solid #E1E1E2", marginTop: '15px', marginBottom: '15px' }}
      >
        <img
          src={IconClosed}
          alt="Cerrar"
          onClick={onClose}
          style={{
            position: "absolute",
            top: "34px",
            right: "34px",
            cursor: "pointer",
            width: "24px",
            height: "24px",
          }}
        />
        <div className="text-center mt-3 mb-[36px]">
          <h3 className="mt-[16px] text-heading-md font-bold">
            Editar Información de perfil
          </h3>
          <p
            className="font-medium px-[55px] text-body-sm mt-[10px]"
            onClick={() => console.log(fieldConfigs, rex_user)}
          >
            Por favor, a continuación edita tu información
          </p>
        </div>
        <Row gutter={[16, 16]}>
          {fieldConfigs.map((field, index) => (
            <Col
              xxl={12} xl={12} md={12}
              key={field.name}
            >
              <div>
                <span className="text-body-md font-medium text-[#5F5F5F]">
                  {field.label || index}
                </span>
                {
                  field.type && field.type == 'select' ? (
                    <SelectBasic
                      showSearch
                      placeholder="Seleccionar"
                      size="large"
                      value={formData[field?.name] || ""}
                      onChange={(e) => handleInputChange(e, field.name)}
                      options={field.values}
                      style={{
                        border: "1px solid #E1E1E2",
                        borderRadius: "8px",
                        height: "36px",
                      }}
                    />
                  ) : (
                    <InputC
                      placeholder=""
                      value={formData[field.name] || ""}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleInputChange(e.target.value, field.name)
                      }}
                      style={{
                        borderRadius: "12px",
                        height: "36px",
                      }}
                    />
                  )
                }
              </div>
            </Col>
          ))}
        </Row>
        <div className="mb-[32px] mt-[20px]">
          <Row gutter={[16, 16]}>
            <Col xxl={12} xl={12} xs={12} sm={12} md={12} lg={4}>
              <p className="text-body-md mb-[2px] font-medium text-[#5F5F5F]">País</p>
              <SelectBasic
                placeholder="País"
                style={{
                  width: "100%", height: '36px',
                  border: "1px solid #E1E1E2",
                  borderRadius: "8px"
                }}
                onChange={(value, val: any) => {
                  setFormData({ ...formData, pais_id: val.id });
                  setIsAddingNewCountry(false);
                  setSelectedCountry(value)
                }}
                loading={loading}
                options={paises.map((country: any) => ({
                  value: country.pais,
                  label: country.pais,
                  id: country.id
                }))}
                defaultValue={rex_user.pais?.pais}
                showSearch={false}
              />
            </Col>
            <Col xxl={12} xl={12} xs={12} sm={12} md={12} lg={4}>
              <p className="text-body-md mb-[2px] font-medium text-[#5F5F5F]">Ciudad</p>
              <SelectBasic
                placeholder="Ciudad"
                style={{
                  width: "100%", height: '36px',
                  border: "1px solid #E1E1E2",
                  borderRadius: "8px"
                }}
                disabled={!selectedCountry && !rex_user.ciudad}
                options={availableCities.map((city: any) => ({
                  value: city.ciudad,
                  label: city.ciudad
                }))}
                onChange={(citie) => {
                  setFormData({ ...formData, ciudad: citie });
                  setSearchCiudad(citie)
                }}
                defaultValue={rex_user.ciudad}
                showSearch={false}
              />
            </Col>
          </Row>
          <div className="flex justify-center mt-[40px]">
            <Button
              onClick={onClose}
              className="principal-nav-notify-buttonG w-[118px] h-[44px]"
              style={{ marginRight: "8px", borderRadius: "4px" }}
            >
              Cancelar
            </Button>
            <Button
              disabled={rex_loading || isSubmitDisabled}
              onClick={handleSaveChanges}
              className={
                `w-[181px] h-[44px] ml-[18px] rounded-[4px] 
                ${rex_loading
                  ? 'bg-[#F4F4F5] text-[#757575] font-semibold text-body-md cursor-not-allowed'
                  : 'bg-blue3 principal-nav-notify-button2 text-white cursor-pointer'
                }
                `
              }
            >
              Guardar cambios
            </Button>
          </div>
        </div>
      </Modal>

      <ModalSaved
        visible={isSecondModalVisible}
        onClose={() => setIsSecondModalVisible(false)}
      />
    </>
  );
};

export default ModalEditProfile;