export const FETCH_OFFER_DETAIL_REQUEST = 'FETCH_OFFER_DETAIL_REQUEST';
export const FETCH_OFFER_DETAIL_SUCCESS = 'FETCH_OFFER_DETAIL_SUCCESS';
export const FETCH_OFFER_DETAIL_FAILURE = 'FETCH_OFFER_DETAIL_FAILURE';
export const UPDATE_OFFER = 'UPDATE_OFFER';

interface FetchOfferDetailRequestAction {
  type: typeof FETCH_OFFER_DETAIL_REQUEST;
}

export interface DetailSoftSkillsFormat { 
  soft_skills: { 
    soft_skill: any
    soft_skill_c: any 
  }
  nivel: number 
}

export interface DataSoftSkill {
  [key: string]: string | number | undefined;
  subject: string;
  idxSubject?: number,
  A?: number;
  B?: number;
  C?: number;
  D?: number;
  E?: number;
  F?: number;
  G?: number;
  H?: number;
  I?: number;
  J?: number;
  fullMark: number;
}


interface FetchOfferDetailSuccessAction {
  type: typeof FETCH_OFFER_DETAIL_SUCCESS;
  payload: any; // Tipo de la oferta detallada
}

interface FetchOfferDetailFailureAction {
  type: typeof FETCH_OFFER_DETAIL_FAILURE;
  payload: string;
}

interface UpdateOfferlSuccessAction {
  type: typeof UPDATE_OFFER;
  payload: [];
}

export type OfferDetailActionTypes =
  | FetchOfferDetailRequestAction
  | FetchOfferDetailSuccessAction
  | FetchOfferDetailFailureAction
  | UpdateOfferlSuccessAction;