import React from 'react';
import { Slider } from 'antd';
import '../../../../styles/components/pages/offers/createOffers/Slider.css'

interface SliderProps {
  minLabel: string;
  maxLabel: string;
  defaultValue?: number;
  hasValue?: boolean;
  skillId: number; // Add skillId prop
  onChange: (value: number) => void; // Add onChange handler
}

const App: React.FC<SliderProps> = ({
  minLabel,
  maxLabel,
  defaultValue = 0,
  skillId,
  hasValue = false,
  onChange
}) => {
  const handleChange = (value: number) => {
    onChange(value);
  };

  const props = hasValue ? { value: defaultValue } : { defaultValue: defaultValue }

  return (
    <>
      <div className="flex justify-between w-full mb-1">
        <span className="text-gray font-medium text-body-sm font-sans">{minLabel}</span>
        <span className="text-gray font-medium text-body-sm font-sans">{maxLabel}</span>
      </div>
      <div className='bg-[#F7F7F7] h-[44px] content-center mb-[10px]'>
        <Slider
          {...props}
          className='slider-createoffer'
          min={0}
          max={10}
          step={1}
          trackStyle={{ backgroundColor: '#007AFF', height: 4 }}
          railStyle={{ backgroundColor: '#FDBCB4', height: 4 }}
          onChange={handleChange}
        />
      </div>
    </>
  );
};

export default App;