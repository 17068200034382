import React, { useEffect, useState } from "react";
import { Avatar, Tag, Select, Button, Divider, Skeleton, Row, Col } from "antd";
import {
  Radar,
  RadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { useNavigate } from "react-router-dom";
import "../../styles/pages/offers/OffersProfileInfo.css";
import StyledCheckbox from "../../checkbox/CheckboxProps";
import RatingBlue from "../../rating/RatingBlue";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store/store";
import dayjs from "dayjs";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomLegend from "../offers/offersProfile/Legend";
import ModalVerCv from "../../modals/ModalVerCv";

const { Option } = Select;
const IMAGE_USER_DEFAULT = process.env.REACT_APP_IMAGE_USER_DEFAULT;
const API_BASE_URL_EXACT = process.env.REACT_APP_API_BASE_URL_EXACT;

interface DetailSoftSkillsFormat {
  soft_skills: {
    soft_skill: any
    soft_skill_c: any
  }
  nivel: number
}

const PostulationSkeleton = () => (
  <div className="p-6 bg-gray-100 rounded-lg relative mb-8">
    <div className="flex flex-wrap -mx-3 relative">
      <div className="w-full md:w-3/4 px-3 relative">
        <div className="flex items-center mb-4">
          <Skeleton.Avatar active size={64} shape="square" />
          <div className="ml-4 flex-grow">
            <Skeleton.Input style={{ width: "60%" }} active size="small" />
            <Skeleton.Input style={{ width: "40%" }} active size="small" />
          </div>
        </div>
        <Skeleton active paragraph={{ rows: 2 }} />
        <Skeleton active paragraph={{ rows: 2 }} />
        <Skeleton active paragraph={{ rows: 2 }} />
        <Skeleton active paragraph={{ rows: 1 }} />
      </div>
      <div className="w-full md:w-1/4 px-3 mt-2 md:mt-0 relative">
        <div style={{ height: "200px" }}></div>
      </div>
    </div>
  </div>
);

interface OffersSearchCVProps {
  educations?: string[];
  searchTerm?: string;
  selectedCountry?: string;
  selectedCity?: string;
  killerQuestion: boolean;
  fetchMoreData: () => void;
  hasMore: boolean;
}

interface CvSelected {
  nombre: string;
  cv: string
}


const OffersSearchCV: React.FC<OffersSearchCVProps> = ({
  educations,
  searchTerm,
  selectedCountry,
  selectedCity,
  killerQuestion = true,
  hasMore = true,
  fetchMoreData
}) => {

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const {
    cvGeneral,
    error,
    rex_limit,
    rex_total
  } = useSelector(
    (state: RootState) => state.getSearchCV
  );

  const [loadingMoreData, setLoadingMoreData] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const handleCandidateClick = (candidateId: number) => {
    navigate(`/searchCV/candidate/${candidateId}`);
  };

  const [showModalCv, setShowModalCv] = useState(false)
  const [cvSelected, setCvSelected] = useState<CvSelected>({ nombre: '', cv: '' })

  const calculateDuration = (startDate: string, endDate: string | null) => {
    const start = dayjs(startDate);
    const end = endDate ? dayjs(endDate) : dayjs();
    const months = end.diff(start, "month");
    const years = Math.floor(months / 12);
    const remainingMonths = months % 12;
    return `${years} años, ${remainingMonths} meses`;
  };


  const getRadarChartData = (userId: number, data: any[]) => {
    const user = data.find(user => user.id === userId);
    if (!user) {
      console.warn(`User with ID ${userId} not found`);
      return [];
    }
    return formatDataSoftsKill(user.soft_skills_usuarios)
  };

  // if (loading) {
  //   return (
  //     <div>
  //       <PostulationSkeleton />
  //       <PostulationSkeleton />
  //       <PostulationSkeleton />
  //     </div>
  //   );
  // }

  // if (error) return <div>Error al cargar los CV: {error}</div>;

  // if (!cvGeneral || cvGeneral.length === 0)
  //   return <div>No hay datos disponibles</div>;

  const defaultSoftSkills = [
    { subject: 'Adaptable', A: 0, fullMark: 10 },
    { subject: 'Consistente', A: 0, fullMark: 10 },
    { subject: 'Detallista', A: 0, fullMark: 10 },
    { subject: 'Innovador', A: 0, fullMark: 10 },
    { subject: 'Metódico', A: 0, fullMark: 10 },
    { subject: 'Proactivo', A: 0, fullMark: 10 },
    { subject: 'Resiliente', A: 0, fullMark: 10 },
    { subject: 'Visionario', A: 0, fullMark: 10 },
  ];

  const formatDataSoftsKill = (data: any) => {

    if (data.length > 0) {
      const dataFormated = data.flatMap((item: DetailSoftSkillsFormat) => [
        {
          subject: item.soft_skills.soft_skill,
          A: item.nivel,
          fullMark: 10
        },
        {
          subject: item.soft_skills.soft_skill_c,
          A: 10 - item.nivel,
          fullMark: 10
        }
      ])

      return dataFormated.sort((a: any, b: any) => {
        if (a.subject < b.subject) return -1
        if (a.subject > b.subject) return 1
        return 0
      })
    } else {
      return defaultSoftSkills
    }
  }

  return (
    <div className="bg-gray-100 rounded-lg relative">
      {/* <Button
        onClick={() => {
          console.log('getRadarChartData:', getRadarChartData(12,cvGeneral))
        }}>
        Aqui
      </Button> */}
      <InfiniteScroll
        dataLength={cvGeneral.length}
        next={fetchMoreData}
        hasMore={hasMore && cvGeneral.length >= rex_limit} // Solo permite más carga si hay más de `rex_limit` resultados
        loader={
          hasMore && cvGeneral.length >= rex_limit ? ( // Solo muestra el Skeleton si `hasMore` es `true` y hay más resultados para cargar
            <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
              <Skeleton avatar paragraph={{ rows: 3 }} active />
            </div>
          ) : null
        }
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Ya no hay más datos</b>
          </p>
        }

      >
        {cvGeneral.map((cv, index) => (
          <React.Fragment key={cv.id}>
            <Row gutter={[16, 16]} className="flex flex-wrap">
              <Col xs={24} xl={15} xxl={16}>
                <div className="w-full px-1 relative">
                  <div
                    onClick={() => handleCandidateClick(cv.id)}
                    style={{ position: "relative", zIndex: 1 }}
                    className="mx-auto p-1 bg-gray-100 rounded-lg cursor-pointer"
                  >
                    <div className="flex items-center cursor-pointer">
                      <StyledCheckbox value={0} children={undefined} />
                      <Avatar
                        size={64}
                        shape="square"
                        className="!rounded-none bg-white"
                        src={
                          cv.imagen
                            ? API_BASE_URL_EXACT + cv.imagen
                            : IMAGE_USER_DEFAULT
                        }
                      />
                      <div className="ml-4">
                        <h2 className="text-[18px] font-medium text-[#1A1A1A] opacity-70">
                          {`${cv.personas.nombre} ${cv.personas.apellido_paterno} ${cv.personas.apellido_materno}`}
                        </h2>
                        <p className="text-[12px] font-medium text-[#1A1A1A] opacity-70">
                          {cv.ubicacion}
                        </p>
                      </div>

                    </div>

                    <div className="flex items-center ml-[19px] mt-4">
                      <h3 className="font-bold text-[12px]">Valoraciones</h3>
                      <div className="flex items-center ml-4 space-x-2">
                        <RatingBlue
                          totalStars={5}
                          filledStars={
                            cv.valoraciones_usuarios.length > 0
                              ? cv.promedioValoraciones
                              : 0
                          }
                        />
                        <Tag className="text-[12px] font-medium text-[#1A1A1A] opacity-70 border border-black rounded-full">
                          Ver valoraciones
                        </Tag>
                      </div>
                    </div>

                    <div className="mt-4 ml-[26px] flex">
                      <h3 className="font-bold mr-4 text-[12px]">Experiencia</h3>
                      <div className="text-[14px] font-medium text-[#1A1A1A] opacity-70">
                        {cv.experiencias_laborales_usuarios.map((exp, index) => (
                          index < 2 && <p key={index}>
                            {`${exp.cargo} | ${exp.nombre_empresa} | ${exp.nombre_sector
                              } | ${dayjs(exp.fecha_inicio).format("MM/YYYY")} - ${exp.fecha_fin
                                ? dayjs(exp.fecha_fin).format("MM/YYYY")
                                : "Actual"
                              } (${calculateDuration(
                                exp.fecha_inicio,
                                exp.fecha_fin
                              )})`}
                          </p>
                        ))}
                      </div>
                    </div>

                    <div className="mt-4 ml-[46px] flex">
                      <h3 className="font-bold mr-4 text-[12px]">Idiomas</h3>
                      <div className="text-[14px] font-medium text-[#1A1A1A] opacity-70">
                        {cv.idiomas_usuarios.map((idioma, index) => (
                          <Tag
                            key={index}
                            className=" mr-2s text-[12px] font-medium text-[#5E5E5E] opacity-70 border border-black rounded-full mb-1"
                          >
                            {idioma.niveles_idiomas.idiomas.idioma}{" "}
                            <span className="font-bold text-caption text-[#5E5E5E]">
                              {idioma.niveles_idiomas.nivel}
                            </span>
                          </Tag>
                        ))}
                      </div>
                    </div>

                    <div className="mt-2 ml-[34px] flex">
                      <h3 className="font-bold mr-[16px] text-[12px]">Educación</h3>
                      <div className="text-[14px] font-medium text-[#1A1A1A] opacity-70">
                        {cv.educacion_usuarios.map((edu, index) => (
                          index < 2
                          && <p key={index}>
                            {`${edu.carrera} | ${edu.nombre_centro_educativo
                              } | ${dayjs(edu.fecha_inicio).format("MM/YYYY")} - ${edu.fecha_final
                                ? dayjs(edu.fecha_final).format("MM/YYYY")
                                : "Actual"
                              } (${calculateDuration(
                                edu.fecha_inicio,
                                edu.fecha_final
                              )})`}
                          </p>
                        ))}
                      </div>
                    </div>

                    <div className="-mt-2 ml-[107px]">
                      <span className="font-bold text-[12px] text-[#1A1A1A] opacity-80">
                        Búsqueda activa | Remoto admitido | Jornada completa
                      </span>
                      <Button
                        type="link"
                        className="font-bold text-[12px] p-0"
                        style={{ color: "#00476D" }}
                        disabled={cv.cvs_usuarios.length == 0}
                        onClick={(e) => {
                          e.stopPropagation()
                          if (cv.cvs_usuarios.length > 0) {
                            setCvSelected({
                              ...cvSelected,
                              nombre: `${cv.personas.nombre} ${cv.personas.apellido_paterno} ${cv.personas.apellido_materno}`,
                              cv: cv.cvs_usuarios[0]['cv']
                            }
                            )
                            setShowModalCv(true)
                          }
                        }}
                      >
                        &nbsp;|&nbsp;&nbsp;Ver CV en pdf&nbsp;&nbsp;|
                      </Button>
                    </div>

                    <div className="flex mt-1 items-center">
                      <div className="flex-1 ml-[35px]">
                        <span className="font-bold mr-4 text-[12px]">Aptitudes</span>
                        {cv.aptitudes_usuarios.map((aptitud, index) => (
                          <Tag
                            key={index}
                            className="mr-2 text-[12px] font-medium text-[#1A1A1A] opacity-70 border border-black rounded-full"
                          >
                            {aptitud.aptitudes.aptitud}
                          </Tag>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col xs={24} xl={9} xxl={8} style={{padding:'0'}} >
                <div
                  // className="w-full md:w-1/4 px-3 mt-2 md:mt-0 relative"
                  style={{
                    // background: 'red',
                  }}
                >
                  <p className="text-end text-[12px] font-medium text-[#1A1A1A] opacity-70">
                    Soft Skills
                  </p>
                  <div
                    style={{
                      zIndex: 2,
                    }}
                  >
                    <div style={{ height: '350px', }}>
                      <ResponsiveContainer>
                        <RadarChart
                          cx="45%"
                          cy="50%"
                          outerRadius="55%"
                          data={getRadarChartData(cv.id, cvGeneral)}
                        >
                          <PolarGrid />
                          <PolarAngleAxis
                            dataKey="subject"
                            tick={{ fontSize: 11, fill: "#000", fontWeight: "Bold" }}
                          />
                          <PolarRadiusAxis axisLine={false} tick={false} angle={30} tickCount={11} domain={[0, 10]} />
                          <Radar
                            name="Perfil del Candidato"
                            dataKey="A"
                            stroke="#006497"
                            fill="transparent"
                          />
                          <Radar
                            name="Perfil de la oferta"
                            dataKey="B"
                            stroke="#FDBCB4"
                            fill="transparent"
                          />
                          <Legend content={<CustomLegend showOffer={false} />} />
                          <Tooltip />
                        </RadarChart>
                      </ResponsiveContainer>
                    </div>
                    <div style={{ height: '35px' }}></div>

                  </div>

                </div>
              </Col>

            </Row>
            {index < cvGeneral.length - 1 && (
              <Divider className="bg-black my-8 " />
            )}
          </React.Fragment>
        ))}
      </InfiniteScroll>
      <ModalVerCv
        nombre={cvSelected.nombre}
        showModal={showModalCv}
        setShowModal={setShowModalCv}
        cv={cvSelected.cv}
      />
    </div>
  );
};

export default OffersSearchCV;
