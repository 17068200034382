import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../../store/store';
import {
  FETCH_POSTULACIONES_REQUEST,
  FETCH_POSTULACIONES_SUCCESS,
  FETCH_POSTULACIONES_FAILURE,
  UPDATE_CV_OR_VE_REQUEST,
  UPDATE_CV_OR_VE_SUCCESS,
  UPDATE_CV_OR_VE_FAILURE,
  PostulacionesActionTypes,
  SET_FILTER_PARAMS,
  Postulacion,
  UPDATE_DATA_POSTULACIONES,
  UPDATE_DATA_SOFT_SKILLS_OFFER,
  DetailSoftSkillsFormat,
  UPDATE_USERS_GRAPH_SK
} from '../../../../../constants/pages/offers/offersProfile/GetOffersProfile';
import FetchWithIP from '../../../utils/FetchHeaders';
import { DetailSoftSkillsOfferFormat } from '../../../../../constants/pages/searchCV/GetSearchCV';
import { experienciaTexto } from '../../../../../utils/CalculateTimeExp';

export const fetchPostulacionesRequest = (): PostulacionesActionTypes => ({
  type: FETCH_POSTULACIONES_REQUEST,
});

export const fetchPostulacionesSuccess = (data: Postulacion[], meta: { total: number; limit: number; page: number }): PostulacionesActionTypes => ({
  type: FETCH_POSTULACIONES_SUCCESS,
  payload: { data, meta },
});

export const updateDataPostulaciones = (data: Postulacion[]): PostulacionesActionTypes => ({
  type: UPDATE_DATA_POSTULACIONES,
  payload: { data },
});

export const updateDataSoftSkillsOffer = (data:any): PostulacionesActionTypes => ({
  type: UPDATE_DATA_SOFT_SKILLS_OFFER,
  payload: data,
});

export const updateCvOrVeRequest = ( postulacionId: number): PostulacionesActionTypes => ({
  type: UPDATE_CV_OR_VE_REQUEST,
  payload: {
    postulacionId,
  }
});

export const updateCvOrVeSuccess = (updatedPostulacion: Postulacion): PostulacionesActionTypes => ({
  type: UPDATE_CV_OR_VE_SUCCESS,
  payload: {
    updatedPostulacion,
  }
});
export const UpdateUsersGraphSkReducer = (): PostulacionesActionTypes => ({
  type: UPDATE_USERS_GRAPH_SK,
  payload: { 
    data : {data : [], users:[]}
  }
});

export const updateCvOrVeFailure = (error: string): PostulacionesActionTypes => ({
  type: UPDATE_CV_OR_VE_FAILURE,
  payload: {
    error,
  }
});

export const fetchPostulacionesFailure = (error: string): PostulacionesActionTypes => ({
  type: FETCH_POSTULACIONES_FAILURE,
  payload: error,
});

export const setFilterParams = (filters: any): PostulacionesActionTypes => ({
  type: SET_FILTER_PARAMS,
  payload: filters,
});


export const addOrRemoveUserGraphSoftSkills = (
  data:{ id:number, nombre: string, softSkillsData:{ soft_skills_usuarios:[], soft_skills_oferta:[]}, checked : boolean},
  addUser:boolean,
  seeGraph: boolean
): ThunkAction<Promise<any>, RootState, unknown, any> => async (dispatch, getState) => {

  try {

    let { rex_users_graph_sk, labels_graph_sk, postulaciones, soft_skills_offer } = getState().getOffersProfile;

    if(seeGraph){

      if(!data.checked ){

        const count_users:number = rex_users_graph_sk.users.length
        const idxKeyUser:number = labels_graph_sk[count_users]['id']
  
        if(data.softSkillsData.soft_skills_usuarios?.length > 0){
  
          const dataFormated = data.softSkillsData.soft_skills_usuarios.flatMap((item: DetailSoftSkillsFormat)=> [
            {
              subject: item.soft_skills.soft_skill,
              value: item.nivel,
            },
            {
              subject: item.soft_skills.soft_skill_c,
              value: 10 - item.nivel,
            }
          ])
          
          dataFormated.forEach((df: {value:number, subject:string}) => {
            const idxSubject:number = rex_users_graph_sk.data.findIndex(ds => ds.subject == df.subject)
            if(idxSubject != -1){            
              rex_users_graph_sk.data[idxSubject][data.id] = df.value
            }else{
              rex_users_graph_sk.data.push({
                subject: df.subject,
                idxSubject : idxKeyUser,
                fullMark : 10,
                [data.id] : df.value
              })
            }
          })
  
          rex_users_graph_sk.users.push({
            nombre : `${data.nombre}`,
            id: data.id,
            nameKey: data.id.toString()
          })
  
        }else{
  
          if(count_users == 0){

            rex_users_graph_sk.data.push(
              { subject: 'Adaptable', idxSubject : 0, fullMark : 10, [data.id] : 0 },
              { subject: 'Consistente', idxSubject : 0, fullMark : 10, [data.id] : 0 },
              { subject: 'Detallista', idxSubject : 0, fullMark : 10, [data.id] : 0 },
              { subject: 'Innovador', idxSubject : 0, fullMark : 10, [data.id] : 0 },
              { subject: 'Metódico', idxSubject : 0, fullMark : 10, [data.id] : 0 },
              { subject: 'Proactivo', idxSubject : 0, fullMark : 10, [data.id] : 0 },
              { subject: 'Resiliente', idxSubject : 0, fullMark : 10, [data.id] : 0 },
              { subject: 'Visionario', idxSubject : 0, fullMark : 10, [data.id] : 0 },
            )

          }else{
            rex_users_graph_sk.data.map(dat => {
              dat[data.id] = 0
            })
          }
          
          rex_users_graph_sk.users.push({
            nombre : `${data.nombre}`,
            id: data.id,
            nameKey: data.id.toString()
          })
        }
      }
    }else{

      if(addUser){
  
        const count_users:number = rex_users_graph_sk.users.length
        const idxKeyUser:number = labels_graph_sk[count_users]['id']
  
        if(data.softSkillsData.soft_skills_usuarios.length > 0){
  
          const dataFormated = data.softSkillsData.soft_skills_usuarios.flatMap((item: DetailSoftSkillsFormat)=> [
            {
              subject: item.soft_skills.soft_skill,
              value: item.nivel,
            },
            {
              subject: item.soft_skills.soft_skill_c,
              value: 10 - item.nivel,
            }
          ])
          
          dataFormated.forEach((df: {value:number, subject:string}) => {
            const idxSubject:number = rex_users_graph_sk.data.findIndex(ds => ds.subject == df.subject)
            if(idxSubject != -1){            
              rex_users_graph_sk.data[idxSubject][data.id] = df.value
            }else{
              rex_users_graph_sk.data.push({
                subject: df.subject,
                idxSubject : idxKeyUser,
                fullMark : 10,
                [data.id] : df.value
              })
            }
          })
  
          rex_users_graph_sk.users.push({
            nombre : `${data.nombre}`,
            id: data.id,
            nameKey: data.id.toString()
          })
  
        }else{
  
          if(count_users == 0){
            rex_users_graph_sk.data.push(
              { subject: 'Adaptable', idxSubject : 0, fullMark : 10, [data.id] : 0 },
              { subject: 'Consistente', idxSubject : 0, fullMark : 10, [data.id] : 0 },
              { subject: 'Detallista', idxSubject : 0, fullMark : 10, [data.id] : 0 },
              { subject: 'Innovador', idxSubject : 0, fullMark : 10, [data.id] : 0 },
              { subject: 'Metódico', idxSubject : 0, fullMark : 10, [data.id] : 0 },
              { subject: 'Proactivo', idxSubject : 0, fullMark : 10, [data.id] : 0 },
              { subject: 'Resiliente', idxSubject : 0, fullMark : 10, [data.id] : 0 },
              { subject: 'Visionario', idxSubject : 0, fullMark : 10, [data.id] : 0 },
            )
          }else{
            rex_users_graph_sk.data.map(dat => {
              dat[data.id] = 0
            })
          }
  
          rex_users_graph_sk.users.push({
            nombre : `${data.nombre}`,
            id: data.id,
            nameKey: data.id.toString()
          })
        }
      }else{
  
        rex_users_graph_sk.users = rex_users_graph_sk.users.filter(user => user.id != data.id)
        rex_users_graph_sk.data.map(dat => {
          delete dat[data.id]
          return dat
        })
      }
    }

    const idxUser: number = postulaciones.findIndex(pst => pst.usuarios.id == +data.id)
    postulaciones[idxUser]['checked'] = addUser
    dispatch(updateDataPostulaciones(postulaciones))

    if(soft_skills_offer.length == 0){
      
      const dataOfertas = data.softSkillsData.soft_skills_oferta.flatMap((item: DetailSoftSkillsOfferFormat, index: number) => [
        {
          subject: item.soft_skills.soft_skill,
          value: (item.porcentaje/10),
        },
        {
          subject: item.soft_skills.soft_skill_c,
          value: 10 - (item.porcentaje/10),
        }
      ]
      )
  
      dataOfertas.forEach((dat:any, index:any) => {
        const idxSubject:number = rex_users_graph_sk.data.findIndex(ds => ds.subject == dat.subject)
        if(idxSubject != -1){            
          rex_users_graph_sk.data[idxSubject][0] = dat.value
        }else{
          rex_users_graph_sk.data.push({
            subject: dat.subject,
            idxSubject : 0,
            fullMark : 10,
            [data.id] : dat.value
          })
        }
      })

      if(!rex_users_graph_sk.users.find(ofr => ofr.id == 0)){
        rex_users_graph_sk.users.unshift({
          nombre : `Perfil de la oferta`,
          id: 0,
          nameKey: "0"
        })
      }
  
      dispatch(updateDataSoftSkillsOffer(dataOfertas))
    }

    rex_users_graph_sk.data.sort((a:any, b:any) => {
      if (a.subject < b.subject) return -1
      if (a.subject > b.subject) return 1
      return 0
    })

  } catch (error) {
    // dispatch(fetchInscritosByDiaFailureReducer('Error '));
    return { payload: [] };
  }
};

export const GetPostulacionesByOfertaReducer = (
  idOferta?: number,
  page?: number,
  limit?: number,
  valoraciones_empresasArray?: number[],
  experiencia_laboralArray?: string[],
  soft_skillsArray?: string[],
  paisesArray?: string[],
  ciudadesArray?: string[],
  empresasArray?: string[],
  meses_experienciaArray?: number[],
  centrosEducativosArray?: string[],
  sectoresArray?: string[],
  valoraciones_usuariosmax?: boolean,
  experiencia_nula?: number[],
  modalidad_usuariosArray?: string[],
  excluir_experiencia_laboralArray?: string[],
  activeSearch?:boolean,
  jornadaArray?:string[],
  excluir_EmpresaArray?: string[],
  excluir_Exper?:string[]

): ThunkAction<Promise<any>, RootState, unknown, PostulacionesActionTypes> => async (dispatch) => {
  dispatch(fetchPostulacionesRequest());

  if (valoraciones_empresasArray) {
    dispatch(setFilterParams(valoraciones_empresasArray));
  }
  if (experiencia_laboralArray) {
    dispatch(setFilterParams(experiencia_laboralArray));
  }
  if (soft_skillsArray) {
    dispatch(setFilterParams(soft_skillsArray));
  }
  if (paisesArray) {
    dispatch(setFilterParams(paisesArray));
  }
  if (ciudadesArray) {
    dispatch(setFilterParams(ciudadesArray));
  }
  if (empresasArray) {
    dispatch(setFilterParams(empresasArray));
  }
  if (sectoresArray) {
    dispatch(setFilterParams(sectoresArray));
  }
  if (valoraciones_usuariosmax) {
    dispatch(setFilterParams(valoraciones_usuariosmax));
  }
  if (experiencia_nula) {
    dispatch(setFilterParams(experiencia_nula));
  }
  if (modalidad_usuariosArray) {
    dispatch(setFilterParams(modalidad_usuariosArray));
  }
  if (activeSearch) {
    dispatch(setFilterParams(activeSearch));
  }
  if (jornadaArray) {
    dispatch(setFilterParams(jornadaArray));
  }
  if (excluir_EmpresaArray) {
    dispatch(setFilterParams(excluir_EmpresaArray));
  }
  if (excluir_Exper) {
    dispatch(setFilterParams(excluir_Exper));
  }
  if (excluir_experiencia_laboralArray) {
    dispatch(setFilterParams(excluir_experiencia_laboralArray));
  }

  const queryParams = new URLSearchParams({
    sortOrder: 'desc',
    ...(page !== undefined && { page: page.toString() }),
    ...(limit !== undefined && { limit: limit.toString() }),
  }).toString();

  try {
    const response = await FetchWithIP(`postulaciones/findAllPostulacionesByOferta/${idOferta}?${queryParams}`, {
      method: 'POST',
    }, {
      centrosEducativosArray,
      meses_experienciaArray,
      valoraciones_empresasArray,
      valoraciones_usuariosmax,
      sectoresArray,
      paisesArray,
      ciudadesArray,
      soft_skillsArray,
      experiencia_laboralArray,
      excluir_experiencia_laboralArray,
      empresasArray,
      experiencia_nula,
      modalidad_usuariosArray,
      activeSearch,
      jornadaArray,
      excluir_EmpresaArray,
    }
    );

    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }

    const responseData = await response.json();

    if (responseData && responseData.data) {
      const { total, limit, page } = responseData.meta;
      const dataPostulaciones = responseData.data.map((postulacion:any)=> ({
        ...postulacion,
        id: postulacion.usuarios.id.toString(),
        nombre: `${postulacion.usuarios.personas.nombre} ${postulacion.usuarios.personas.apellido_paterno} ${postulacion.usuarios.personas.apellido_materno}`,
        valoraciones: postulacion.usuarios.promedioValoraciones,
        numReviews: postulacion.usuarios.valoraciones_usuarios?.length || 0,
        aptitudes: postulacion.usuarios.aptitudes_usuarios.map((apt: any) => `${apt.aptitudes.aptitud}`),
        softSkills: "Ver Gráfica",
        ubicacion: postulacion.usuarios.ubicacion,
        estudios: postulacion.usuarios.educacion_usuarios.map((carrera: any) => `${carrera.carrera}`),
        experiencia: experienciaTexto(postulacion.usuarios.meses_experiencia),
        rol: postulacion.usuarios.cargo || "Rol no especificado",
        softSkillsData: {
          soft_skills_usuarios: postulacion.usuarios.soft_skills_usuarios || [],
          soft_skills_oferta: postulacion.ofertas.soft_skills_oferta || []
        }
      }))
      dispatch(fetchPostulacionesSuccess(
        dataPostulaciones, { total, limit, page }
      ))

      dispatch(updateDataSoftSkillsOffer([]))
      dispatch(UpdateUsersGraphSkReducer())
      return responseData;
    } else {
      throw new Error('Datos vacíos en la respuesta');
    }
  } catch (error: any) {
    dispatch(fetchPostulacionesFailure(error.message || 'Error al cargar las postulaciones'));
    return { error: error.message };
  }};

export const updateCvOrVe = (
  postulacionId: number, 
  candidatura?: string
): ThunkAction<Promise<any>, RootState, unknown, PostulacionesActionTypes> => 
  async (dispatch) => {
    dispatch(updateCvOrVeRequest(postulacionId));

    try {
      const response = await FetchWithIP(`postulaciones/updateCVorVE/${postulacionId}`, {
        method: 'PATCH',
      },{
        candidatura
      }
    );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Error HTTP: ${response.status}`);
      }

      const responseData = await response.json();

      dispatch(updateCvOrVeSuccess(responseData.data));
      return responseData;
    } catch (error: any) {
      dispatch(updateCvOrVeFailure(error.message || 'Error al actualizar el estado de la postulación'));
      return { error: error.message };
    }
};